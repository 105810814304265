// import axios from 'axios'

export default {
  state: {
    banners: []
  },
  mutations: {
    loadBanner (state, payload) {
      state.banners = payload
    }
  },
  actions: {
    async fetchBanner ({ commit }) {
      commit('clearError')
      commit('setLoading', true)

      // const resultSteps = []

      // base
      function base (ms) {
        return new Promise(resolve => {
          // setTimeout(resolve, ms)
          setTimeout(() => {
            resolve(
              [
                { title: 'Competenz lab', text: 'Our Individual Coaching and Mentoring Services are designed to help individuals change their career. They range from Career Orientation to Cognitive Behavioral Coaching and everything in between.' },
                { title: 'Competenz lab', text: 'We help clients discover what they really want to do, and how to do it.' },
                { title: 'Competenz lab', text: 'Platform architecture is an easy-to-use, multi-university platform that allows you to focus less on operational matters and more on coaching and guiding your clients toward fulfilling career goals.' },
                { title: 'Competenz lab', text: 'Our platform includes individualized assessments, career coaching appointments, helpful tools and informational resources, and a strong community of supportive peers to help them as they progress through the process.' }
              ]
            )
          }, ms)
        })
      }
      // base the end
      try {
        const val = await base(200)
        // const val = await axios.post('https://jsonplaceholder.typicode.com/posts')

        commit('loadBanner', val)
        commit('setLoading', false)
      } catch (error) {
        commit('setError', error)
        commit('setLoading', false)
      }
    }
  },
  getters: {
    banners (state) {
      return state.banners
    }
  }
}
