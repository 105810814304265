<template>
  <div class="container-cs lg client-modification">
    <lab-header></lab-header>
    <div>
      <div class="lb-tp-120 lb-tp-md-40">
        <div class="lb-mb-64 lb-mb-sm-40">
          <div class="welcome-block h1">
            welcome to competenz lab
            <span class="icon">
              <img src="@/assets/img/star-09234.svg" alt="icon">
            </span>
          </div>
        </div>
        <div class="stepper-container" ref="stepper">
          <div class="color-grey stepper-mob-title" v-if="stepsProfileType.length">Step {{indexActiveStep + 1}}: {{stepsProfileTitle}}</div>
          <div v-if="stepsProfileType.length">
            <lab-stepper
              ref="stepper_container"
              :steps='stepsProfileType'
              :keep-alive='false'
              :done="finishTextBtn"
              @active-step='isStepActive($event), stepsCreated($event)'
              @stepper-finished='nextStep'
            ></lab-stepper>
            <div class="btn-wrapper stepper-container-buttons">
              <button
                class="btn btn-primary stepper-container-next"
                :class="{'disable': !canContinue}"
                @click="next()"
              >
                Next
              </button>
              <button
                class="btn btn-outlined stepper-container-skip"
                @click="skip()"
              >
                Skip
              </button>
            </div>
          </div>
          <div v-else class="loader-wrapper">
            <div class="loader-circle">
              <img src="@/assets/img/loader-icon.png" alt="loader">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LabHeader from '@/components/Header'
import LabStepper from 'vue-stepper'

export default {
  data () {
    return {
      indexActiveStep: 0,
      finishTextBtn: 'Next'
    }
  },
  computed: {
    stepsProfileTitle () {
      return this.stepsProfileType[this.indexActiveStep].title
    },
    stepsProfileType () {
      return this.$store.getters.steps
    },
    usr: function () {
      return this.$store.getters.usr
    },
    canContinue () {
      return this.$store.getters.canContinue
    }
  },
  methods: {
    isStepActive: function (v) {
      this.indexActiveStep = v.index
      this.$store.commit('canContinue', false)
    },
    stepsCreated: function () {
      setTimeout(function () {
        const n = 100 / this.stepsProfileType.length
        if (this.$refs.stepper) {
          this.$refs.stepper.querySelector('.divider-line').style.width = (this.stepsProfileType.length - 1) * n + '%'
        }
      }.bind(this), 200)
    },
    next: function () {
      const a = this.$refs.stepper_container
      this.$store.dispatch('sendStepsAnswer', this.indexActiveStep)
        .then(() => {
          a.canContinue = true
          a.nextStep()
        })
    },
    skip: function () {
      const a = this.$refs.stepper_container
      this.$store.commit('updateStepsAnswerAn', [])
      this.$store.dispatch('sendStepsAnswer', this.indexActiveStep)
        .then(() => {
          a.canContinue = true
          a.nextStep()
        })
    },
    nextStep: function () {
      window.location.href = 'https://app.competenzlab.io/dashboard/career/?usr=' + this.usr
    }
  },
  created () {
    const v = {
      indexStep: 0,
      answer: []
    }
    this.$store.commit('updateStepsAnswer', v)
    this.$store.dispatch('fetchSteps')
  },
  components: {
    LabHeader,
    LabStepper
  }
}
</script>

<style lang="scss">
  @import "../../assets/scss/_variables.scss";
  @import "../../assets/scss/_stepper.scss";
</style>
