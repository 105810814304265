<template>
  <div class="container-cs">
    <div class="row justify-content-center align-items-center lab-success-page">
      <div class="col-lg-8">
        <div class="text-center">
          <a class="lab-success-logo" href="#"><img src="@/assets/img/logo.svg" alt="logo"></a>
          <div class="lab-success-image lb-mb-40 lb-xl-mb-15">
            <div class="decor-bg pos-bottom"></div>
            <img src="@/assets/img/succes-image.png" alt="image">
          </div>
          <div class="row justify-content-center">
            <div class="col-xl-6 col-lg-10">
              <div class="h1 color-dark lb-mb-15">Thank you!</div>
              <div class="text color-grey lb-mb-32">
                <p>We’ve received your application! Our team is reviewing your application and we’ll be in touch with an email on next steps.</p>
              </div>
              <a :href="'https://coach.competenzlab.io/?usr=' + user_key" class="btn btn-primary btn-fixed-sm">Go to Dashboard</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  data () {
    return {}
  },
  computed: {
    user_key: function () {
      return this.$store.getters.coach_usr
    }
  }
}
</script>

<style lang="scss">
  @import "../../assets/scss/_variables.scss";
  @import "../../assets/scss/_success-page.scss";
</style>
