import axios from 'axios'
export default {
  state: {
    user: null,
    usr: null
  },
  mutations: {
    autoLoginUser (state, payload) {
      state.user = payload
    },
    usrUpload (state, payload) {
      state.usr = payload
    }
  },
  actions: {
    async autoLoginUser ({ commit, getters }, payload) {
      try {
        const v = await axios({
          method: 'get',
          url: getters.baseURL + 'apicall/init?check=' + payload,
          headers: {
            'Access-Control-Allow-Origin': '*'
          }
        })
        commit('autoLoginUser', v.data)
      } catch (error) {
        console.log(error.response)
        console.log(error)
        commit('setError', error)
      }
    }
  },
  getters: {
    user (state) {
      return state.user
    },
    usr (state) {
      return state.usr
    }
  }
}
