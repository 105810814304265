<template>
  <div class="lb-tp-40">
    <div class="h2 lb-mb-20">{{getTitle}}</div>
    <div class="row">
      <div class="col-xl-6 col-lg-8">
        <div class="search-block">
          <div class="search-block-title">
            <div class="text color-dark text-media lb-mb-5">Specific skills</div>
            <div class="text color-grey">Select the learned skill to analyze your data</div>
          </div>
          <div class="search-block-input">
            <div class="input-wrapper-icon clean">
              <span class="icon"><img src="@/assets/img/icons/icon-search.svg" alt="icon-search"></span>
              <button
                class="icon-clean"
                v-if="searchSkills.length"
                @click="searchSkills = ''"
              >
                <img src="@/assets/img/icons/icon-clear.svg" alt="icon-search">
              </button>
              <input class="input sm" placeholder="Search for skills" type="text" v-model="searchSkills">
            </div>
          </div>
          <div class="checkbox-wrapper">
            <label class="checkbox-entry" v-for="(option, n) in optionsFilter" :key="n">
              <input type="checkbox" class="subscribe-input" :value="option.key" v-model="optionVal">
              <span>{{option.value}}</span>
            </label>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['clickedNext', 'currentStep'],
  data () {
    return {
      optionVal: [],
      searchSkills: ''
    }
  },
  computed: {
    getStep () {
      return this.$store.getters.steps[this.currentStep.index]
    },
    getOptions () {
      return this.getStep.options
    },
    getTitle () {
      return this.getStep.titleStep
    },
    optionsFilter () {
      return this.getOptions.filter(goal => {
        if (this.optionVal.includes(goal.value)) {
          return true
        } else {
          return goal.value.toLowerCase().includes(this.searchSkills.toLowerCase())
        }
      })
    }
  },
  watch: {
    optionVal: function (val) {
      if (val.length > 0) {
        this.$emit('can-continue', { value: true })
        // post answe
        const v = {
          indexStep: this.currentStep.index,
          answer: val
        }
        this.$store.commit('updateStepsAnswer', v)
        // post answe the end
      } else {
        this.$emit('can-continue', { value: false })
      }
    }
  },
  mounted () {
    this.$store.commit('is_send', true)
    if (this.optionVal.length > 0) {
      this.$emit('can-continue', { value: true })
      // post answe
      const v = {
        indexStep: this.currentStep.index,
        answer: this.optionVal
      }
      this.$store.commit('updateStepsAnswer', v)
      // post answe the end
    } else {
      this.$emit('can-continue', { value: false })
    }
  }
}
</script>

<style lang="scss">
@import "../assets/scss/_variables.scss";

.search-block{
  background-color: #fff;
  padding: 24px;
  border-radius: 16px;
  &-input{
    margin-top: 12px;
    margin-bottom: 15px;
  }
}
@media (max-width: 575px){
  .search-block{
    background-color: rgba(1,1,1,0);
    padding: 0;
    border-radius: 0;
    &-title{
      display: none;
    }
    &-input{
      margin-top: 0;
    }
  }
}
</style>
