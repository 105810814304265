<template>
  <div class="lb-tp-60">
    <div v-if="getStep.isTitleBlock">
      <div class="title-wrapper">
          <span class="icon">
            <img src="@/assets/img/icons/rocket-icon-0234.svg" alt="icon">
          </span>
        <div class="h2 fw-700 lb-mb-5">Profile type</div>
        <div class="text-md color-grey2">Objectives and goals: what do you want to achieve?</div>
      </div>
    </div>
    <div class="wrapper-layer">
      <div class="h2 lb-mb-20">{{getTitle}}</div>
      <div class="row">
        <div class="col-sm-9 col-lg-6">
          <div class="btn-wrapper-or">
            <!--
            <button
              @click="auth_linkedin"
              class="btn btn-outlined btn-block-sm"
            >Get From Linkedin</button>
            <div class="text-md color-grey">or</div>
            -->
            <button
                @click="triggerUpload"
                class="btn btn-outlined btn-block-sm"
            >Upload CV</button>
          </div>
          <div class="upload-wrapper lb-mb-15">
            <div
                class="upload-die"
                :class="{'loader': fileLoader}"
                v-if="fileUpload"
            >
            <span class="icon">
              <img src="@/assets/img/icons/icon-ok.svg" alt="icon">
            </span>
              <div class="loader-wrapper">
                <div class="loader-circle">
                  <img src="@/assets/img/loader-icon.png" alt="loader">
                </div>
              </div>
              <span class="file">{{fileUploadInfo.name}}</span>
              <span class="size">{{fileUploadInfo.size}} MB</span>
              <button
                  class="btn clean"
                  @click="fileUpload = null"
              >
                <img src="@/assets/img/icons/icon-clear.svg" alt="icon">
              </button>
            </div>
            <input
                @change="changeFile($event)"
                ref="fileInput"
                type="file"
                style="display: none"
                accept="application/pdf,application/vnd.ms-excel"
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import { url, minLength } from 'vuelidate/lib/validators'
export default {
  props: ['clickedNext', 'currentStep'],
  data () {
    return {
      fileUpload: null,
      filePath: '',
      fileLoader: false
    }
  },
  computed: {
    fileUploadInfo () {
      return {
        name: this.fileUpload.name,
        size: Math.ceil(this.fileUpload.size / (1024 * 1024))
      }
    },
    getStep () {
      return this.$store.getters.steps[this.currentStep.index]
    },
    getTitle () {
      return this.getStep.titleStep
    },
    userSecurity () {
      return this.$store.getters.user
    }
  },
  methods: {
    focusInput: function (e) {
      e.target.closest('.input-wrapper-material').classList.add('focus')
    },
    blurInput: function (e) {
      e.target.closest('.input-wrapper-material').classList.remove('focus')
    },
    typedInput: function (e) {
      if (e.target.value) {
        e.target.closest('.input-wrapper-material').classList.add('typed')
      } else {
        e.target.closest('.input-wrapper-material').classList.remove('typed')
      }
    },
    triggerUpload: function () {
      this.$refs.fileInput.click()
    },
    changeFile: function (event) {
      const file = event.target.files[0]
      const formData = new FormData()
      formData.set('file', file)
      this.fileUpload = file
      this.fileLoader = true
      // POST FILE
      this.$axios.post(this.$root.$data.baseURL + 'apicall/storecv', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: 'Bearer ' + this.userSecurity.token,
          'X-Api-Key': this.userSecurity.key
        }
      })
        .then((r) => {
          this.filePath = r.data.path
          this.fileLoader = false
          this.$emit('can-continue', { value: true })
        })
        .catch(function (e) {
          console.log(e.response)
          this.fileLoader = false
          this.$emit('can-continue', { value: false })
        })
      // POST FILE (end)
    },
    handlePostMessage: function (event) {
      if (event.data.type === 'profile') {
        if (event.data.profile) {
          this.$axios({
            method: 'post',
            headers: {
              Authorization: 'Bearer ' + this.userSecurity.token,
              'X-Api-Key': this.userSecurity.key
            },
            url: this.$root.$data.baseURL + 'apicall/client/linkedin',
            params: {
              token: event.data.profile
            }
          })
            .then((r) => {
              console.log(r)
              this.$emit('can-continue', { value: true })
            })
            .catch(function (e) {
              console.log(e.response)
            })
        }
      }
    },
    auth_linkedin: function () {
      console.log('linkedin')
      const clientID = '78z45km206otzp'
      const redirectURL = window.location.origin + '/lin'

      const oauthUrl = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${clientID}&scope=r_liteprofile&state=123456&redirect_uri=${redirectURL}`
      const width = 450
      const height = 730
      const left = window.screen.width / 2 - width / 2
      const top = window.screen.height / 2 - height / 2

      window.open(
        oauthUrl,
        'Linkedin',
        'menubar=no,location=no,resizable=no,scrollbars=no,status=no, width=' +
        width +
        ', height=' +
        height +
        ', top=' +
        top +
        ', left=' +
        left
      )
    }
  },
  mounted () {
    const v = {
      indexStep: this.currentStep.index,
      answer: ''
    }
    this.$store.commit('updateStepsAnswer', v)
    window.addEventListener('message', this.handlePostMessage)
  }
}
</script>

<style lang="scss">
.upload-die{
  .loader-wrapper{
    display: none;
  }
  &.loader{
    .loader-wrapper{
      display: block;
      position: absolute;
      width: 20px;
      padding: 0;
      top: 50%;
      left: 12px;
      transform: translateY(-50%);
      line-height: 0;
    }
    .loader-circle{
      width: 16px;
    }
    .icon{
      display: none;
    }
  }
}
.btn-wrapper-or{
  display: flex;
  align-items: center;
  > * {
    margin-right: 8px;
    &:last-child{
      margin-right: 0;
    }
  }
}
</style>
