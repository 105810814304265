<template>
  <div class="lb-tp-40">
    <div v-if="!loadingLocal">
      <div class="h3 lb-mb-15">Name the career coaching area you consider yourself and rank it</div>
      <div v-if="optionArea.length > 0" class="checkbox-wrapper lb-mb-32">
        <label class="checkbox-entry" v-for="(item, i) in optionArea" :key="i">
          <input type="checkbox" class="subscribe-input" :name="i" :value="item" v-model="value.answerArea">
          <span>{{item.title}}</span>
        </label>
      </div>
      <div class="h3 lb-mb-15">What problem can you help with?</div>
      <div v-if="optionProblem.length > 0" class="checkbox-wrapper lb-mb-32">
        <label class="checkbox-entry" v-for="(item, i) in optionProblem" :key="i">
          <input type="checkbox" class="subscribe-input" :name="i" :value="item" v-model="value.answerProblem">
          <span>{{item.title}}</span>
        </label>
      </div>
      <div class="h3 lb-mb-15">Define skills that you best possess as a career coach and rank it</div>
      <div v-if="optionSkills.length > 0" class="checkbox-wrapper">
        <label class="checkbox-entry" v-for="(item, i) in optionSkills" :key="i">
          <input type="checkbox" class="subscribe-input" :name="i" :value="item" v-model="value.answerSkills">
          <span>{{item.title}}</span>
        </label>
      </div>
    </div>
    <div v-else class="loader-wrapper">
      <div class="loader-circle">
        <img src="@/assets/img/loader-icon.png" alt="loader">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['clickedNext', 'currentStep'],
  data () {
    return {
      optionVal: [],
      optionArea: [],
      loadingArea: true,
      optionProblem: [],
      loadingProblem: true,
      optionSkills: [],
      loadingSkills: true,
      value: {
        answerArea: [],
        answerProblem: [],
        answerSkills: []
      }
    }
  },
  computed: {
    getStep () {
      return this.$store.getters.steps[this.currentStep.index]
    },
    loadingLocal () {
      if (!this.loadingArea && !this.loadingProblem && !this.loadingSkills) {
        return false
      } else {
        return true
      }
    },
    userSecurity () {
      return this.$store.getters.user
    }
  },
  watch: {
    value: {
      handler (val) {
        if (val.answerArea.length > 0 && val.answerProblem.length > 0 && val.answerSkills.length > 0) {
          this.$emit('can-continue', { value: true })
        } else {
          this.$emit('can-continue', { value: false })
        }
      },
      deep: true
    }
  },
  mounted () {
    if (this.optionVal.length > 0) {
      this.$emit('can-continue', { value: true })
    } else {
      this.$emit('can-continue', { value: false })
    }
    this.$axios({
      method: 'get',
      url: this.$root.$data.baseURL + 'apicall/coach/getcoachingareas'
    })
      .then((r) => {
        this.optionArea = r.data
        this.loadingArea = false
      })
      .catch(function (e) {
        this.loadingArea = false
      })
    this.$axios({
      method: 'get',
      url: this.$root.$data.baseURL + 'apicall/coach/getproblemstohelp'
    })
      .then((r) => {
        this.optionProblem = r.data
        this.loadingProblem = false
      })
      .catch(function (e) {
        this.loadingProblem = false
      })
    this.$axios({
      method: 'get',
      url: this.$root.$data.baseURL + 'apicall/coach/getcoachskills'
    })
      .then((r) => {
        this.optionSkills = r.data
        this.loadingSkills = false
      })
      .catch(function (e) {
        this.loadingSkills = false
      })
  },
  beforeDestroy () {
    if (this.value.answerArea.length > 0 && this.value.answerProblem.length > 0 && this.value.answerSkills.length > 0) {
      const v = [
        {
          answer_id: this.value.answerArea[0].id,
          type: 'career_coaching_areas'
        },
        {
          answer_id: this.value.answerProblem[0].id,
          type: 'problem_to_helps'
        },
        {
          answer_id: this.value.answerSkills[0].id,
          type: 'coach_skills'
        }
      ]
      this.$axios({
        method: 'post',
        headers: {
          Authorization: 'Bearer ' + this.userSecurity.token,
          'X-Api-Key': this.userSecurity.key
        },
        url: this.$root.$data.baseURL + 'apicall/coach/saveansers',
        params: {
          data: JSON.stringify(v)
        }
      })
        .then((r) => {
          console.log('DONE')
        })
        .catch(function (e) {
          console.log(e.response)
        })
    }
  }
}
</script>
