import LabStepCheckbox from '@/components/LabStepCheckbox'
import LabStepTwo from '@/components/LabStepTwo'
import LabStepCheckboxSearch from '@/components/LabStepCheckboxSearch'
import LabStepFifth from '@/components/LabStepFifth'
import LabStepRadio from '@/components/LabStepRadio'
import axios from 'axios'

function getComponents (c) {
  switch (c) {
    case 'LabStepCheckbox': return LabStepCheckbox
    case 'LabStepTwo': return LabStepTwo
    case 'LabStepCheckboxSearch': return LabStepCheckboxSearch
    case 'LabStepFifth': return LabStepFifth
    case 'LabStepRadio': return LabStepRadio
  }
}

export default {
  actions: {
    async fetchStepsJobDemands ({ commit, getters }) {
      commit('clearError')
      commit('setLoading', true)
      try {
        const val = await axios.get(getters.baseURL + 'apicall/onboarding/jobdemands', {
          headers: {
            Authorization: 'Bearer ' + getters.user.token,
            'X-Api-Key': getters.user.key
          }
        })
        const resultSteps = []
        let options = []

        /* FORMATION OF STEPS */
        // step 1
        options = []
        val.data.green.forEach((e) => {
          options.push({
            value: e.text,
            indenture: {
              code: e.code,
              mark: 8
            }
          })
        })
        resultSteps.push({
          name: 1,
          component: getComponents('LabStepRadio'),
          completed: false,
          options: options
        })
        // step 2
        options = []
        val.data.red.forEach((e) => {
          options.push({
            value: e.text,
            indenture: {
              code: e.code,
              mark: -8
            }
          })
        })
        resultSteps.push({
          name: 2,
          component: getComponents('LabStepRadio'),
          completed: false,
          options: options
        })
        // step 3
        options = []
        val.data.green.forEach((e) => {
          options.push({
            value: e.text,
            indenture: {
              code: e.code,
              mark: 6
            }
          })
        })
        val.data.red.forEach((e) => {
          options.push({
            value: e.text,
            indenture: {
              code: e.code,
              mark: -6
            }
          })
        })
        resultSteps.push({
          name: 3,
          component: getComponents('LabStepRadio'),
          completed: false,
          options: options
        })
        // step 3
        options = []
        val.data.green.forEach((e) => {
          options.push({
            value: e.text,
            indenture: {
              code: e.code,
              mark: 4
            }
          })
        })
        val.data.red.forEach((e) => {
          options.push({
            value: e.text,
            indenture: {
              code: e.code,
              mark: -4
            }
          })
        })
        resultSteps.push({
          name: 4,
          component: getComponents('LabStepRadio'),
          completed: false,
          options: options
        })
        commit('loadSteps', resultSteps)
        commit('setLoading', false)
      } catch (error) {
        commit('setError', error)
        commit('setLoading', false)
      }
    }
  }
}
