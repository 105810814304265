import store from '@/store'

export default function (to, from, next) {
  if (store.getters.user) {
    redirect(to, from, next)
  } else {
    if (to.query.usr) {
      store.commit('usrUpload', to.query.usr)
      store.dispatch('autoLoginUser', to.query.usr).then((r) => {
        if (store.getters.user) {
          redirect(to, from, next)
        } else {
          window.location.replace(store.getters.loginURL)
        }
      }, (error) => {
        window.location.replace(store.getters.loginURL)
        console.log(error)
      })
    } else {
      window.location.replace(store.getters.loginURL)
    }
  }
}
const redirect = function (to, from, next) {
  if (store.getters.user.type === 'coach') {
    next('/choose-role')
  } else if (store.getters.user.type === 'client') {
    next('/client')
  } else {
    console.log(store.getters.user)
    window.location.replace(store.getters.loginURL)
  }
}
