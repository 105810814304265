<template>
  <div>
    <div class="container-cs">
      <div class="row">
        <div class="col-lg-8">
          <lab-header></lab-header>
          <div class="lb-tp-80">
            <div class="h1 lb-mb-8 color-dark">Coach registration</div>
            <div class="text color-grey">
              <p>Interested in joining the platform? Fill in the required fields,<br> then after moderation you will get access.</p>
            </div>
            <div class="stepper-container lb-tp-30" ref="stepper">
              <div v-if="stepsJobDemands.length">
                <lab-stepper
                  ref="stepper_container"
                  :steps='stepsJobDemands'
                  :keep-alive='false'
                  @active-step='isStepActive($event), stepsCreated($event)'
                  @stepper-finished='nextStep'
                ></lab-stepper>
                <button
                  class="btn btn-primary stepper-container-skip"
                  @click="skip()"
                  v-if="stepsJobDemands[indexActiveStep].skip"
                >
                  Skip
                </button>
              </div>
              <div v-else class="loader-wrapper">
                <div class="loader-circle">
                  <img src="@/assets/img/loader-icon.png" alt="loader">
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-4">
          <lab-banner></lab-banner>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LabHeader from '@/components/Header'
import LabBanner from '@/components/LabBanner'
import LabStepper from 'vue-stepper'

export default {
  data () {
    return {
      indexActiveStep: 0,
      finishTextBtn: 'Finish'
    }
  },
  computed: {
    stepsJobDemands () {
      return this.$store.getters.steps
    }
  },
  methods: {
    isStepActive: function (v) {
      this.indexActiveStep = v.index
    },
    stepsCreated: function () {
      setTimeout(function () {
        const n = 100 / this.stepsJobDemands.length
        this.$refs.stepper.querySelector('.divider-line').style.width = (this.stepsJobDemands.length - 1) * n + '%'
        if ((this.indexActiveStep + 1) === this.stepsJobDemands.length) {
          this.$refs.stepper.querySelector('.stepper-button.next > span').innerHTML = this.finishTextBtn
        }
      }.bind(this), 200)
    },
    nextStep: function () {
      this.$router.push('/coach/success')
    },
    skip: function () {
      const a = this.$refs.stepper_container
      a.canContinue = true
      a.nextStep()
    }
  },
  created () {
    this.$store.dispatch('fetchCoachSteps')
  },
  watch: {
    stepsJobDemands: function (v) {
      console.log(v)
    },
    indexActiveStep: function (v) {
      console.log(v)
    }
  },
  components: {
    LabHeader,
    LabBanner,
    LabStepper
  }
}
</script>

<style lang="scss">
@import "../../assets/scss/_variables.scss";
@import "../../assets/scss/_stepper.scss";
</style>
<style lang="scss">
.stepper-container{
  position: relative;
}
@media (max-width: 575px) {
  .stepper-container{
    padding-bottom: 60px;
    /*
    &-skip{
      position: fixed !important;
      bottom: 58px !important;
      right: 16px !important;
      left: 16px !important;
      width: calc(100% - 32px);
    }
    */
  }
}
</style>
