<template>
  <div>
    loading...
  </div>
</template>
<script>
export default {
  data () {
    return {
      state: '',
      url: 'https://abbacore.loc/lin',
      client_id: '78z45km206otzp',
      client_secret: 'fcqWG66gLKhIWBG8'
    }
  },
  created () {
    window.opener.postMessage({ type: 'profile', profile: this.$route.query.code }, '*')
    window.close()
  }
}
</script>
