<template>
  <div class="lb-tp-40">
    <template v-if="!loading_employment">
      <div class="h2 lb-mb-20">Do you plan to change your country of employment?</div>
      <div class="lb-mb-24">
        <div class="row">
          <div class="col-xl-5 col-lg-7">
            <div class="checkbox-wrapper">
              <label class="checkbox-entry">
                <input type="radio" class="subscribe-input" name="change-country" value="hide" v-model="isChangeCountry">
                <span>No, I do not want to change my country of employment</span>
              </label>
              <label class="checkbox-entry">
                <input type="radio" class="subscribe-input" name="change-country" value="show" v-model="isChangeCountry">
                <span>Yes, I am planning to move to another country</span>
              </label>
            </div>
            <div class="lb-tp-15" v-if="isChangeCountry === 'show'">
              <div
                  class="select-wrapper-material"
                  :class="{'selected': value_country.length, 'visible-only-first': disable_country}"
              >
                <label>Country</label>
                <multi-select
                  v-model="value_country"
                  :btnLabel="v => v.length ?  labelSelect(v) : 'Select'"
                  :options="{labelName: 'name', multi: true}"
                  :selectOptions="getSelectDate"
                >
                  <template v-slot:option="{option}">
                    <div
                      class="checkbox-entry type2"
                      :class="{'disable': disable_country && option.key !== 'maybe_later'}"
                    >
                      <input type="checkbox" class="subscribe-input" :checked="option.selected">
                      <span>{{option.name}}</span>
                    </div>
                  </template>
                </multi-select>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="h2 lb-mb-20">What is your ideal style of work</div>
      <div class="row">
        <div class="col-xl-5 col-lg-7">
          <div class="select-wrapper-material" :class="{'selected': type_employment.length}" >
            <label>Employment Type</label>
            <multi-select
              v-model="type_employment"
              labelName="title"
              :btnLabel="btnLabel"
              :selectOptions="options_employment"
            />
          </div>
        </div>
      </div>
    </template>
    <div v-else class="loader-wrapper">
      <div class="loader-circle">
        <img src="@/assets/img/loader-icon.png" alt="loader">
      </div>
    </div>
  </div>
</template>

<script>
import MultiSelect from 'vue-multi-select'
import 'vue-multi-select/dist/lib/vue-multi-select.css'

export default {
  props: ['currentStep'],
  data () {
    return {
      isChangeCountry: 'hide',
      btnLabel: values => values.length ? values[0].name : '',
      value_country: [],
      disable_country: false,
      loading_employment: true,
      options_employment: [],
      type_employment: [],
      maybe_later: {
        key: 'maybe_later',
        name: 'Maybe later'
      }
    }
  },
  computed: {
    multipleWatch () {
      return { one: this.value_country, two: this.isChangeCountry, th: this.type_employment }
    },
    getStep () {
      return this.$store.getters.steps[this.currentStep.index]
    },
    getSelectDate () {
      const a = [this.maybe_later]
      this.getStep.options.forEach((e) => {
        a.push({
          key: e.key,
          name: e.value
        })
      })
      return a
    },
    getTitle () {
      return this.getStep.titleStep
    },
    userSecurity () {
      return this.$store.getters.user
    }
  },
  methods: {
    checkValid: function () {
      const v = {
        indexStep: this.currentStep.index,
        country: this.value_country.length > 0 && this.value_country.find(e => e.key === 'maybe_later') ? 0 : this.value_country.length > 0 ? this.value_country.map(e => e.key) : '',
        relocate: this.isChangeCountry === 'hide' ? 'No' : 'Yes',
        empl_type: this.type_employment.length > 0 ? this.type_employment[0].id : 0
      }
      this.$store.commit('updateStepsAnswer', v)
      // post answe the end
      if (this.isChangeCountry === 'hide') {
        this.$emit('can-continue', { value: true })
      } else {
        if (this.value_country.length > 0) {
          this.$emit('can-continue', { value: true })
        } else {
          this.$emit('can-continue', { value: false })
        }
      }
    },
    labelSelect: function (e) {
      if (e.length > 1) {
        return e[0].name + ', ... (' + e.length + ')'
      } else {
        return e[0].name
      }
    }
  },
  watch: {
    multipleWatch: {
      handler: function () {
        this.checkValid()
      },
      deep: true
    },
    value_country: {
      handler: function (v) {
        const a = v.find((e) => e.key === 'maybe_later')
        if (a) {
          this.disable_country = true
        } else {
          this.disable_country = false
        }
      },
      deep: true
    },
    disable_country: function (v) {
      if (v) {
        this.value_country = [this.maybe_later]
      }
    }
  },
  mounted () {
    this.$store.commit('is_send', true)
    this.checkValid()
    this.$axios({
      method: 'get',
      headers: {
        Authorization: 'Bearer ' + this.userSecurity.token,
        'X-Api-Key': this.userSecurity.key
      },
      url: this.$root.$data.baseURL + 'apicall/client/empl_types'
    })
      .then((r) => {
        const d = []
        r.data.forEach((e) => {
          d.push({
            id: e.id,
            name: e.title
          })
        })
        this.options_employment = d
        this.loading_employment = false
      })
      .catch(function (e) {
        console.log(e.response)
        this.loading_employment = false
      })
  },
  components: {
    MultiSelect
  }
}
</script>
<style lang="scss">
.select-wrapper-material{
  &.visible-only-first{
    .selectItem{
      pointer-events: none;
      opacity: 0.4;
      &:first-child{
        pointer-events: auto;
        opacity: 1;
      }
    }
  }
}
</style>
