// import axios from 'axios'
import LabStepAboutYourself from '@/components/LabStepAboutYourself'
import LabStepCheckboxMultiple from '@/components/LabStepCheckboxMultiple'
import LabStepCheckboxSrCoach from '@/components/LabStepCheckboxSrCoach'
import LabStepAchievements from '@/components/LabStepAchievements'

function getComponents (c) {
  switch (c) {
    case 'LabStepAboutYourself': return LabStepAboutYourself
    case 'LabStepCheckboxMultiple': return LabStepCheckboxMultiple
    case 'LabStepCheckboxSrCoach': return LabStepCheckboxSrCoach
    case 'LabStepAchievements': return LabStepAchievements
  }
}

export default {
  state: {
    coachAnswer: '',
    coach_usr: ''
  },
  mutations: {
    uploadCoachUsr (state, payload) {
      state.coach_usr = payload
    }
  },
  actions: {
    fetchCoachSteps ({ commit }) {
      const val = [
        {
          name: 'step1',
          title: 'Personal Data',
          component: 'LabStepAboutYourself',
          completed: false,
          titleStep: 'Fill in information about yourself',
          skip: false
        },
        {
          name: 'step2',
          title: 'Coaching area',
          component: 'LabStepCheckboxMultiple',
          completed: false,
          options: [],
          skip: true
        },
        {
          name: 'step3',
          title: 'Customers',
          component: 'LabStepCheckboxSrCoach',
          completed: false,
          titleStep: 'With customers from which industries you would like to work',
          options: [],
          skip: true
        },
        {
          name: 'step4',
          title: 'Achievements',
          component: 'LabStepAchievements',
          completed: false,
          titleStep: 'Fill in the fields according to your achievements, certificates',
          skip: true
        }
      ]
      const resultSteps = val.map((e) => {
        return {
          completed: e.completed,
          component: getComponents(e.component),
          name: e.name,
          options: e.options,
          titleStep: e.titleStep,
          title: e.title,
          skip: e.skip
        }
      })
      commit('loadSteps', resultSteps)
    }
  },
  getters: {
    coach_usr (state) {
      return state.coach_usr
    }
  }
}
