<template>
  <div class="lb-tp-40">
    <div class="h3 lb-mb-20">{{getTitle}}</div>
    <div class="scroll-js">
      <div class="row">
        <div class="col-xl-6 col-lg-8">
          <div class="search-block ">
            <div class="scroll-inner">
              <div class="search-block-input lb-mt-0">
                <div class="input-wrapper-icon clean">
                  <span class="icon"><img src="@/assets/img/icons/icon-search.svg" alt="icon-search"></span>
                  <button
                    class="icon-clean"
                    v-if="searchSkills.length"
                    @click="searchSkills = ''"
                  >
                    <img src="@/assets/img/icons/icon-clear.svg" alt="icon-search">
                  </button>
                  <input class="input sm" placeholder="Search for industries" type="text" v-model="searchSkills">
                </div>
              </div>
              <div v-if="!loadingOptions">
                <div v-if="options.length > 0" class="checkbox-wrapper">
                  <label class="checkbox-entry" v-for="(option, n) in optionsFilter" :key="n">
                    <input type="checkbox" class="subscribe-input" :value="option.id" v-model="optionValue">
                    <span>{{option.title}}</span>
                  </label>
                </div>
              </div>
              <div v-else class="loader-wrapper">
                <div class="loader-circle">
                  <img src="@/assets/img/loader-icon.png" alt="loader">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['clickedNext', 'currentStep'],
  data () {
    return {
      searchSkills: '',
      options: [],
      optionValue: [],
      loadingOptions: true
    }
  },
  computed: {
    getStep () {
      return this.$store.getters.steps[this.currentStep.index]
    },
    getTitle () {
      return this.getStep.titleStep
    },
    optionsFilter () {
      return this.options.filter(goal => {
        if (this.optionValue.includes(goal.id)) {
          return true
        } else {
          const s = this.searchSkills.toLowerCase()
          return goal.title.toLowerCase().substring(0, s.length) === s
        }
      })
    },
    userSecurity () {
      return this.$store.getters.user
    }
  },
  methods: {
    scrollHG: function () {
      const $e = document.querySelectorAll('.scroll-js')
      $e.forEach((e) => {
        const hd = document.documentElement.offsetHeight
        const t = e.offsetTop
        const b = hd - (t + e.clientHeight)
        const wh = window.innerHeight
        e.querySelector('.scroll-inner').style.height = wh - (t + b + 60) + 'px'
      })
    }
  },
  watch: {
    optionValue: function (val) {
      if (val.length > 0) {
        this.$emit('can-continue', { value: true })
      } else {
        this.$emit('can-continue', { value: false })
      }
    }
  },
  mounted () {
    this.scrollHG()
    if (this.optionValue.length > 0) {
      this.$emit('can-continue', { value: true })
    } else {
      this.$emit('can-continue', { value: false })
    }

    this.$axios({
      method: 'get',
      url: this.$root.$data.baseURL + 'apicall/coach/getindustries'
    })
      .then((r) => {
        this.options = r.data
        this.loadingOptions = false
      })
      .catch(function (e) {
        this.loadingOptions = false
      })
  },
  beforeDestroy () {
    if (this.optionValue.length > 0) {
      this.$axios({
        method: 'post',
        headers: {
          Authorization: 'Bearer ' + this.userSecurity.token,
          'X-Api-Key': this.userSecurity.key
        },
        url: this.$root.$data.baseURL + 'apicall/coach/saveindustry',
        params: {
          data: JSON.stringify(this.optionValue)
        }
      })
        .then((r) => {
          console.log('DONE')
        })
        .catch(function (e) {
          console.log(e.response)
        })
    }
  }
}
</script>

<style lang="scss">
@import "../assets/scss/_variables.scss";

.search-block{
  background-color: #fff;
  padding: 24px;
  border-radius: 16px;
  &-input{
    margin-top: 12px;
    margin-bottom: 15px;
  }
}
.scroll-inner{
  overflow: auto;
  padding: 2px 10px 0 2px;
  width: 100%;
  min-height: 400px;
  box-sizing: content-box;
}
@media (max-width: 1200px){
  .scroll-inner{
    overflow: unset;
    paddingssssss: 0;
    margin-right: 0;
    height: auto!important;
  }
}
@media (max-width: 575px){
  .search-block{
    background-color: rgba(1,1,1,0);
    padding: 0;
    border-radius: 0;
    &-title{
      display: none;
    }
    &-input{
      margin-top: 0;
    }
  }
}
</style>
