<template>
  <div class="lb-tp-20">
<!--    <div class="h2 lb-mb-20">What's your career goal?</div>-->
    <div class="row">
      <div class="col-xl-6 col-lg-9">
        <div class="checkbox-wrapper">

          <label class="checkbox-entry" v-for="(option, n) in getOptions" :key="n" :data-check="option.indenture.code">
            <input type="radio" class="subscribe-input" name="radio-input" :value="option.indenture" v-model="answerOption">
            <span>{{option.value}}</span>
          </label>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['clickedNext', 'currentStep'],
  data () {
    return {
      answerOption: ''
    }
  },
  computed: {
    getOptions () {
      return this.$store.getters.steps[this.currentStep.index].options
    }
  },
  watch: {
    answerOption: function (val) {
      if (val !== '') {
        this.$emit('can-continue', { value: true })
        // post answe
        this.$store.commit('updateStepsAnswer', val)
        // post answe the end
      } else {
        this.$emit('can-continue', { value: false })
      }
    }
  },
  mounted () {
    if (this.answerOption.length > 0) {
      this.$emit('can-continue', { value: true })
      // post answe
      const v = {
        indexStep: this.currentStep.index,
        answer: this.answerOption
      }
      this.$store.commit('updateStepsAnswer', v)
      // post answe the end
    } else {
      this.$emit('can-continue', { value: false })
    }
  }
}
</script>
