import Vue from 'vue'
import Router from 'vue-router'
import AuthGuard from './auth-guard'
import RedirectGuard from './redirect-guard'
import VueGtm from 'vue-gtm'

import ProfileType from '@/views/client/ProfileType'
import JobDemands from '@/views/client/JobDemands'
import LabSuccess from '@/views/client/LabSuccess'
import ChooseRole from '@/views/chooseRole'
import Coach from '@/views/coach'
import CoachSuccess from '@/views/coach/LabSuccess'
import PageNotFound from '@/views/PageNotFound'
import Main from '@/views/main'
import Lin from '@/views/client/lin'
import Elements from '@/views/Elements'

Vue.use(Router)

const routes = [
  {
    path: '/',
    component: Main,
    beforeEnter: RedirectGuard,
    meta: {
      gtm: 'Main'
    }
  },
  {
    name: 'Lin',
    path: '/lin',
    component: Lin,
    meta: {
      gtm: 'Lin'
    }
  },
  {
    path: '/client',
    component: ProfileType,
    beforeEnter: AuthGuard,
    meta: {
      gtm: 'client'
    }
  },
  {
    path: '/jobdemands',
    component: JobDemands,
    beforeEnter: AuthGuard,
    meta: {
      gtm: 'jobdemands'
    }
  },
  {
    path: '/success',
    component: LabSuccess,
    beforeEnter: AuthGuard,
    meta: {
      gtm: 'success'
    }
  },
  {
    path: '/choose-role',
    component: ChooseRole,
    beforeEnter: AuthGuard,
    meta: {
      gtm: 'choose-role'
    }
  },
  {
    path: '/coach',
    component: Coach,
    beforeEnter: AuthGuard,
    meta: {
      gtm: 'coach'
    }
  },
  {
    path: '/coach/success',
    component: CoachSuccess,
    beforeEnter: AuthGuard,
    meta: {
      gtm: 'coach/success'
    }
  },
  {
    path: '*',
    component: PageNotFound
  },
  {
    path: '/elements',
    component: Elements
  }
]

const router = new Router({
  mode: 'history',
  routes
})

Vue.use(VueGtm, {
  id: 'GTM-K4Q45CT',
  enabled: true,
  debug: true,
  vueRouter: router,
  trackOnNextTick: false
})

export default router
