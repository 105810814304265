<template>
  <div class="container-cs">
    <div class="row justify-content-center align-items-center lab-success-page">
      <div class="col-lg-8">
        <div class="text-center">
          <a class="lab-success-logo" href="#"><img src="@/assets/img/logo.svg" alt="logo"></a>
          <div class="lab-success-image lb-mb-40">
            <div class="decor-bg pos-bottom"></div>
            <img src="@/assets/img/succes-image.png" alt="image">
          </div>
          <div class="row justify-content-center">
            <div class="col-lg-6">
              <div class="h1 color-dark lb-mb-15">Successful filling of data</div>
              <div class="text color-grey lb-mb-32">
                <p>We will analyze the data you filled in for further recommendations for learning new skills, training materials.</p>
              </div>
              <div class="btn-wrapper center">
                <a :href="'https://app.competenzlab.io/dashboard/career/?usr=' + usr" class="btn btn-primary btn-fixed-sm">Go to Dashboard</a>
                <button
                  class="btn btn-primary btn-fixed-sm btn-loader"
                  :class="{'loader': loading_report}"
                  @click="show_report"
                >
                  Get a report
                </button>
              </div>
              <!--
              <div v-else-if="!is_done && this.number >= 3" class="text-center">We could not process your resume, go to your account and try uploading again</div>
              <div v-else class="loader-wrapper">
                <div class="loader-circle">
                  <img src="@/assets/img/loader-icon.png" alt="loader">
                </div>
              </div>
              -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  data () {
    return {
      loading_report: false,
      is_done: false,
      number: 0
    }
  },
  computed: {
    usr: function () {
      return this.$store.getters.usr
    },
    userSecurity () {
      return this.$store.getters.user
    }
  },
  methods: {
    show_report: function () {
      this.loading_report = true
      this.$axios({
        method: 'get',
        headers: {
          Authorization: 'Bearer ' + this.userSecurity.token,
          'X-Api-Key': this.userSecurity.key
        },
        url: this.$root.$data.baseURL + 'apicall/client/getreport'
      })
        .then((r) => {
          this.loading_report = false
          window.open(r.data.report, '_blank').focus()
        })
        .catch(function (e) {
          this.loading_report = false
          console.log(e)
        })
    }
  },
  mounted () {
    /*
    const inte = setTimeout(() => {
      this.$axios({
        method: 'get',
        headers: {
          Authorization: 'Bearer ' + this.userSecurity.token,
          'X-Api-Key': this.userSecurity.key
        },
        url: this.$root.$data.baseURL + 'apicall/client/getreport'
      })
        .then((r) => {
          console.log(r)
          if (r.data.report !== '#' && r.data.report) {
            this.is_done = true
          }
          this.number++
          if (this.number >= 3) {
            clearInterval(inte)
          }
        })
        .catch(function (e) {
          console.log(e)
        })
    }, 5000)
    */
  }
}
</script>

<style lang="scss">
  @import "../../assets/scss/_variables.scss";
  @import "../../assets/scss/_success-page.scss";
</style>
