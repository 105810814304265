<template>
  <div>
    <div class="row">
      <div class="col-lg-8">
        <lab-header></lab-header>
        <div class="lb-tp-80">
          <div class="h1 lb-mb-8 color-dark">Job demands</div>
          <div class="text color-grey">
            <p>In order for us to estimate your personal Interests and Usual Style, you will need to answer a series of questions. Read each list of phrases below and decide which one is most describes you, then select the radio button next to that phrase. </p>
            <p>There are 4 total questions.</p>
          </div>
          <div class="stepper-container lb-tp-30" ref="stepper">
            <div v-if="stepsJobDemands.length">
              <lab-stepper
                :steps='stepsJobDemands'
                :keep-alive='false'
                @active-step='isStepActive($event), stepsCreated($event)'
                @stepper-finished='nextStep'
              ></lab-stepper>
            </div>
            <div v-else class="loader-wrapper">
              <div class="loader-circle">
                <img src="@/assets/img/loader-icon.png" alt="loader">
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-4">
        <lab-banner></lab-banner>
      </div>
    </div>
  </div>
</template>

<script>
import LabHeader from '@/components/Header'
import LabBanner from '@/components/LabBanner'
import LabStepper from 'vue-stepper'

export default {
  data () {
    return {
      indexActiveStep: 0,
      finishTextBtn: 'Next step'
    }
  },
  computed: {
    stepsJobDemands () {
      return this.$store.getters.steps
    },
    getStepsAnswer () {
      return this.$store.getters.stepsAnswer
    }
  },
  methods: {
    isStepActive: function (v) {
      this.indexActiveStep = v.index
      if (v.index > 0) {
        const a = this.getStepsAnswer
        a.id = v.index
        this.$store.commit('computedSteps', a)
      }
    },
    stepsCreated: function () {
      setTimeout(function () {
        const n = 100 / this.stepsJobDemands.length
        if (this.$refs.stepper) {
          this.$refs.stepper.querySelector('.divider-line').style.width = (this.stepsJobDemands.length - 1) * n + '%'
          if ((this.indexActiveStep + 1) === this.stepsJobDemands.length) {
            this.$refs.stepper.querySelector('.stepper-button.next > span').innerHTML = this.finishTextBtn
          }
        }
      }.bind(this), 200)
    },
    nextStep: function () {
      const a = this.getStepsAnswer
      a.id = this.isStepActive.index
      this.$store.commit('computedSteps', a)
      this.$store.dispatch('sendJobDemandsAnswer')
      this.$router.push('/success')
    }
  },
  created () {
    this.$store.dispatch('fetchStepsJobDemands')
  },
  components: {
    LabHeader,
    LabBanner,
    LabStepper
  }
}
</script>

<style lang="scss">
@import "../../assets/scss/_variables.scss";
@import "../../assets/scss/_stepper.scss";
</style>
