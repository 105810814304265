<template>
  <div>
    <lab-header></lab-header>
    <div class="container-cs">
      <div class="row align-items-center">
        <div class="col-lg-8">
          <div class="lb-tp-80 lb-bp-80">
            <div class="lb-mb-24">
              <div class="h1 lb-mb-8 color-dark">Choose you role?</div>
              <div class="text color-grey">
                <p>Are you a coach or a mentor?</p>
              </div>
            </div>
            <div class="lb-mb-32">
              <div class="row row-12 align-items-center">
                <div class="col-auto">
                  <label class="badge checkbox-item">
                    <input name="choose-role" value="coach" type="radio" v-model="radio">
                    <span>Coach</span>
                  </label>
                </div>
                <div class="col-auto"><span class="color-grey text-md">or</span></div>
                <div class="col-auto">
                  <label class="badge checkbox-item">
                    <input name="choose-role" value="mentor" type="radio" v-model="radio">
                    <span>Mentor</span>
                  </label>
                </div>
              </div>
            </div>
            <router-link
              :to="'/' + radio"
              tag="button"
              class="btn btn-primary"
              :class="{'disable': !radio}"
            >Next</router-link>
          </div>
        </div>
        <div class="col-lg-4">
          <lab-banner></lab-banner>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LabHeader from '@/components/Header'
import LabBanner from '@/components/LabBanner'

export default {
  data () {
    return {
      radio: ''
    }
  },
  computed: {},
  methods: {},
  watch: {},
  created () {},
  components: {
    LabHeader,
    LabBanner
  }
}
</script>

<style lang="scss">
@import "../assets/scss/_variables.scss";
@import "../assets/scss/_other-elements.scss";
</style>
