import Vue from 'vue'
import Vuex from 'vuex'
import shared from './shared'
import stepsProfileType from './stepsProfileType'
import stepsJobDemands from './stepsJobDemands'
import banner from './banner'
import coach from './coach'
import user from './user'
import axios from 'axios'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    baseURL: 'https://api.competenzlab.io/',
    steps: [],
    stepsAnswer: { indexStep: 0, answer: [] },
    isFile: false,
    file: null,
    computedSteps: [],
    is_send: false,
    loginURL: 'https://competenzlab.io/login',
    canContinue: false
  },
  mutations: {
    canContinue (state, payload) {
      state.canContinue = payload
    },
    is_send (state, payload) {
      state.is_send = payload
    },
    loadSteps (state, payload) {
      state.steps = payload
    },
    clearSteps (state) {
      state.steps = []
    },
    updateStepsAnswer (state, payload) {
      state.stepsAnswer = payload
    },
    updateStepsAnswerAn (state, payload) {
      state.stepsAnswer.answer = payload
    },
    computedSteps (state, payload) {
      state.computedSteps.push(payload)
      state.steps.forEach((e, i) => {
        const c = e.options.filter((a) => {
          return a.indenture.code !== payload.code
        })
        state.steps[i].options = c
      })
    }
  },
  actions: {
    async sendStepsAnswer ({ commit, getters, state }, payload) {
      commit('clearError')
      if (state.is_send) {
        const d = {
          step: payload + 1,
          answers: state.stepsAnswer.answer
        }
        try {
          const v = await axios({
            method: 'post',
            url: getters.baseURL + 'apicall/onboarding/profiletype',
            headers: {
              Authorization: 'Bearer ' + getters.user.token,
              'X-Api-Key': getters.user.key
            },
            data: d,
            params: {
              data: JSON.stringify(d)
            }
          })
          return v
        } catch (error) {
          console.log(error.response)
          commit('setError', error)
          throw error
        }
      }
    },
    async sendJobDemandsAnswer ({ commit, getters, state }) {
      commit('clearError')
      try {
        const v = state.computedSteps.map(function (e) {
          return {
            code: e.code,
            value: e.mark
          }
        })
        const r = await axios({
          method: 'post',
          url: getters.baseURL + 'apicall/onboarding/jobdemands',
          headers: {
            Authorization: 'Bearer ' + getters.user.token,
            'X-Api-Key': getters.user.key
          },
          params: {
            data: JSON.stringify(v)
          }
        })
        commit('usrUpload', r.data.string)
        await axios({
          method: 'get',
          url: getters.baseURL + 'prediction/' + r.data.id,
          headers: {
            Authorization: 'Bearer ' + getters.user.token,
            'X-Api-Key': getters.user.key
          }
        })
      } catch (error) {
        console.error(error.response)
        commit('setError', error)
      }
    }
  },
  getters: {
    baseURL (state, getters, rootState) {
      return rootState.baseURL
    },
    loginURL (state, getters, rootState) {
      return rootState.loginURL
    },
    steps (state) {
      return state.steps
    },
    poll (state) {
      return state.poll
    },
    stepsAnswer (state) {
      return state.stepsAnswer
    },
    canContinue (state) {
      return state.canContinue
    }
  },
  modules: {
    shared,
    stepsProfileType,
    stepsJobDemands,
    coach,
    banner,
    user
  }
})
