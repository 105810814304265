<template>
  <div class="full-height row align-items-center justify-content-center">
    <div class="col-auto">
      <div class="not-page-image">
        <img src="@/assets/img/404.gif" alt="image">
      </div>
      <div class="text-center">
        <div class="h3 lb-mb-15">Page not found</div>
        <router-link tag="button" class="btn btn-secondary" to="/">Back home</router-link>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  data () {
    return {}
  },
  computed: {},
  methods: {},
  mounted () {},
  watch: {},
  created () {},
  components: {}
}
</script>

<style lang="scss">
  @import "../assets/scss/_variables.scss";
  @import "../assets/scss/_stepper.scss";
  .full-height{
    height: calc(100vh - 48px);
  }
  .not-page{
    &-image{
      max-width: 500px;
      margin-bottom: 24px;
      img{
        max-width: 100%;
      }
    }
  }
</style>
