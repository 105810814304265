<template>
  <div class="lb-tp-40">
    <div class="h3 lb-mb-24">{{getTitle}}</div>
    <div v-if="!loadingLocal">
      <div class="row row-12 nowrap align-items-center">
        <div class="col-auto">
          <div class="profile-ed-mn-image">
            <img v-if="imageUploadSrc" :src="imageUploadSrc" alt="image">
            <img v-else src="@/assets/img/default-user-avatar.png" alt="imag">
          </div>
        </div>
        <div class="col-w-100">
          <div class="h4 lb-mb-5">Upload photo</div>
          <div class="color-grey text-md">The photo must be no more than 2 MB in size. We support JPG, PNG.</div>
        </div>
      </div>
      <div class="btn-wrapper lb-tp-15 lb-mb-24">
        <button class="btn btn-outlined" @click="triggerUpload">Upload photo</button>
        <input
          @change="changeFile($event)"
          ref="fileInput"
          type="file"
          style="display: none"
          accept="image/*"
        >
      </div>
      <div class="row">
        <div class="col-lg-7">
          <div class="form-group sm">
            <div class="input-wrapper-material">
              <div class="input-inner">
                <label>Full name</label>
                <input
                  class="input input-material"
                  name="name" type="text"
                  @focus="focusInput"
                  @blur="blurInput($event); $v.userMainInfo.name.$touch()"
                  @input="typedInput"
                  v-model="userMainInfo.name"
                  :class="{'error': $v.userMainInfo.name.$error}"
                >
              </div>
              <div v-if="$v.userMainInfo.name.$error" class="input-error">Error</div>
            </div>
            <div class="input-wrapper-material" :class="{'typed': userMainInfo.birthday}">
              <div class="input-inner">
                <label>Date of Birth</label>
                <vc-date-picker
                  v-model="userMainInfo.birthday"
                  :max-date="computedDate(new Date(), 0, 0, -18)"
                  is24hr
                  :locale="{ id: 'en', masks: { weekdays: 'WW' } }"
                >
                  <template v-slot="{ inputValue, inputEvents }">
                    <input
                      class="input input-material"
                      @focus="focusInput"
                      @blur="blurInput($event); $v.userMainInfo.birthday.$touch()"
                      @input="typedInput"
                      :class="{'error': $v.userMainInfo.birthday.$error}"
                      :value="inputValue"
                      v-on="inputEvents"
                    />
                  </template>
                </vc-date-picker>
              </div>
            </div>
            <div class="select-wrapper-material" ref="selectA" :class="{'selected': userMainInfo.gender.length}" >
              <label>Gender</label>
              <multi-select
                v-model="userMainInfo.gender"
                :btnLabel="v => v.length ? v[0].name : 'Select'"
                :selectOptions="optinGender"
                @open="openSelect($refs.selectA)"
                @close="closeSelect($refs.selectA)"
              />
            </div>
            <div class="input-wrapper-material">
              <div class="input-inner">
                <label>Phone number</label>
                <input
                  class="input input-material"
                  name="name" type="text"
                  @focus="focusInput"
                  @blur="blurInput($event), $v.userMainInfo.phone.$touch()"
                  @input="typedInput"
                  v-model="userMainInfo.phone"
                  :class="{'error': $v.userMainInfo.phone.$error}"
                >
              </div>
              <div v-if="$v.userMainInfo.phone.$error" class="input-error">Error</div>
            </div>
            <div class="input-wrapper-material">
              <div class="input-inner">
                <label>Street Address</label>
                <input
                  class="input input-material"
                  name="name" type="text"
                  @focus="focusInput"
                  @blur="blurInput($event); $v.userMainInfo.address.$touch()"
                  @input="typedInput"
                  v-model="userMainInfo.address"
                  :class="{'error': $v.userMainInfo.address.$error}"
                >
              </div>
              <div v-if="$v.userMainInfo.address.$error" class="input-error">Error</div>
            </div>
            <div class="input-wrapper-material">
              <div class="input-inner">
                <label>E-mail</label>
                <input
                  class="input input-material"
                  name="name" type="text"
                  @focus="focusInput"
                  @blur="blurInput($event); $v.userMainInfo.email.$touch()"
                  @input="typedInput"
                  v-model="userMainInfo.email"
                  :class="{'error': $v.userMainInfo.email.$error}"
                >
              </div>
              <div v-if="$v.userMainInfo.email.$error" class="input-error">Error</div>
            </div>
            <div class="select-wrapper-material search" ref="selectB" :class="{'selected': userMainInfo.country.length}" >
              <label>Country of residence</label>
              <multi-select
                v-model="userMainInfo.country"
                :btnLabel="v => v.length ? v[0].name : 'Select'"
                :selectOptions="countrySelect"
                :search="true"
                @open="openSelect($refs.selectB)"
                @close="closeSelect($refs.selectB)"
              />
            </div>
            <div class="input-wrapper-material">
              <div class="input-inner">
                <label>Website URL</label>
                <input
                  class="input input-material"
                  name="name" type="text"
                  @focus="focusInput"
                  @blur="blurInput($event); $v.userMainInfo.website.$touch()"
                  @input="typedInput"
                  v-model="userMainInfo.website"
                  :class="{'error': $v.userMainInfo.website.$error}"
                >
              </div>
              <div v-if="$v.userMainInfo.website.$error" class="input-error">Error</div>
            </div>
            <div class="select-wrapper-material search" ref="selectC" :class="{'selected': userMainInfo.lang.length}" >
              <label>Mother tongue language</label>
              <multi-select
                v-model="userMainInfo.lang"
                :btnLabel="v => v.length ? v[0].value : 'Select'"
                :selectOptions="languageSelect"
                :search="true"
                :options="{labelName: 'value'}"
                @open="openSelect($refs.selectC)"
                @close="closeSelect($refs.selectC)"
              >
              </multi-select>
            </div>
            <div class="input-wrapper-material textarea">
              <div class="input-inner">
                <label>Own professional background</label>
                <textarea
                  class="input input-material"
                  name="name" type="text"
                  @focus="focusInput"
                  @blur="blurInput($event)"
                  @input="typedInput"
                  v-model="userMainInfo.background"
                ></textarea>
              </div>
            </div>
            <div class="input-wrapper-material">
              <div class="input-inner">
                <label>Quote about myself</label>
                <input
                  class="input input-material"
                  name="name" type="text"
                  @focus="focusInput"
                  @blur="blurInput($event)"
                  @input="typedInput"
                  v-model="userMainInfo.quote"
                >
              </div>
            </div>
            <div class="input-wrapper-material">
              <div class="input-inner">
                <label>LinkedIn link</label>
                <input
                  class="input input-material"
                  name="name" type="text"
                  @focus="focusInput"
                  @blur="blurInput($event); $v.userMainInfo.ln.$touch()"
                  @input="typedInput"
                  v-model="userMainInfo.ln"
                  :class="{'error': $v.userMainInfo.ln.$error}"
                >
              </div>
              <div v-if="$v.userMainInfo.ln.$error" class="input-error">Error</div>
            </div>
            <div class="input-wrapper-material">
              <div class="input-inner">
                <label>Facebook link</label>
                <input
                  class="input input-material"
                  name="name" type="text"
                  @focus="focusInput"
                  @blur="blurInput($event); $v.userMainInfo.fb.$touch()"
                  @input="typedInput"
                  v-model="userMainInfo.fb"
                  :class="{'error': $v.userMainInfo.fb.$error}"
                >
              </div>
              <div v-if="$v.userMainInfo.fb.$error" class="input-error">Error</div>
            </div>
            <div class="input-wrapper-material">
              <div class="input-inner">
                <label>Instagram link</label>
                <input
                  class="input input-material"
                  name="name" type="text"
                  @focus="focusInput"
                  @blur="blurInput($event); $v.userMainInfo.inst.$touch()"
                  @input="typedInput"
                  v-model="userMainInfo.inst"
                  :class="{'error': $v.userMainInfo.inst.$error}"
                >
              </div>
              <div v-if="$v.userMainInfo.inst.$error" class="input-error">Error</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="loader-wrapper">
      <div class="loader-circle">
        <img src="@/assets/img/loader-icon.png" alt="loader">
      </div>
    </div>
  </div>
</template>

<script>
import { required, email, url } from 'vuelidate/lib/validators'
import MultiSelect from 'vue-multi-select'
import 'vue-multi-select/dist/lib/vue-multi-select.css'

export default {
  props: ['clickedNext', 'currentStep', 'done'],
  data () {
    return {
      optinGender: [
        { name: 'Male' },
        { name: 'Female' }
      ],
      countrySelect: [],
      languageSelect: [],
      btnLabel: values => values.length ? values[0].name : 'Select',
      select_options: {
        labelName: 'value'
      },
      valueSelect: [],
      imageUploadSrc: null,
      imagePath: '',
      selectedDate: null,
      userMainInfo: {
        name: '',
        birthday: '',
        email: '',
        phone: '',
        ln: '',
        fb: '',
        inst: '',
        gender: [],
        address: '',
        country: [],
        website: '',
        lang: [],
        timezone: '',
        background: '',
        quote: '',
        photo: ''
      },
      loadingCountry: true,
      loadingLanguage: true
    }
  },
  computed: {
    getStep () {
      return this.$store.getters.steps[this.currentStep.index]
    },
    getOptions () {
      return this.getStep.options
    },
    getTitle () {
      return this.getStep.titleStep
    },
    loadingLocal () {
      if (!this.loadingCountry && !this.loadingLanguage) {
        return false
      } else {
        return true
      }
    },
    userSecurity () {
      return this.$store.getters.user
    }
  },
  methods: {
    computedDate: function (input, days, months, years) {
      return new Date(
        input.getFullYear() + years,
        input.getMonth() + months,
        Math.min(
          input.getDate() + days,
          new Date(input.getFullYear() + years, input.getMonth() + months + 1, 0).getDate()
        )
      )
    },
    focusInput: function (e) {
      e.target.closest('.input-wrapper-material').classList.add('focus')
    },
    blurInput: function (e) {
      e.target.closest('.input-wrapper-material').classList.remove('focus')
    },
    typedInput: function (e) {
      if (e.target.value) {
        e.target.closest('.input-wrapper-material').classList.add('typed')
      } else {
        e.target.closest('.input-wrapper-material').classList.remove('typed')
      }
    },
    openSelect: function (e) {
      e.classList.add('open-select')
    },
    closeSelect: function (e) {
      e.classList.remove('open-select')
    },
    triggerUpload: function () {
      this.$refs.fileInput.click()
    },
    changeFile: function (event) {
      const file = event.target.files[0]
      const formData = new FormData()
      formData.set('photo', file)
      // POST PHOTO
      this.$axios.post(this.$root.$data.baseURL + 'apicall/coach/storephoto', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: 'Bearer ' + this.userSecurity.token,
          'X-Api-Key': this.userSecurity.key
        }
      })
        .then((r) => {
          this.imagePath = r.data.path
        })
        .catch(function (e) {
          console.log(e.response)
        })
      // POST PHOTO (end)
      const reader = new FileReader()
      reader.onload = () => {
        this.imageUploadSrc = reader.result
      }
      reader.readAsDataURL(file)
    }
  },
  watch: {
    userMainInfo: {
      handler (val) {
        if (!this.$v.$invalid) {
          this.$emit('can-continue', { value: true })
        } else {
          this.$emit('can-continue', { value: false })
        }
      },
      deep: true
    }
  },
  mounted () {
    if (!this.$v.$error) {
      this.$emit('can-continue', { value: true })
    } else {
      this.$emit('can-continue', { value: false })
    }

    this.$axios({
      method: 'get',
      url: this.$root.$data.baseURL + 'apicall/getcountries'
    })
      .then((r) => {
        this.countrySelect = r.data
        this.loadingCountry = false
      })
      .catch(function (e) {
        this.loadingCountry = false
      })

    this.$axios({
      method: 'get',
      url: this.$root.$data.baseURL + 'apicall/getlangs'
    })
      .then((r) => {
        this.languageSelect = r.data
        this.loadingLanguage = false
      })
      .catch(function (e) {
        this.loadingLanguage = false
      })
  },
  components: {
    MultiSelect
  },
  validations: {
    userMainInfo: {
      name: {
        required
      },
      birthday: {
        required
      },
      phone: {
        required
      },
      address: {
        required
      },
      email: {
        required,
        email
      },
      website: {
        url
      },
      ln: {
        url
      },
      fb: {
        url
      },
      inst: {
        url
      }
    }
  },
  beforeDestroy () {
    if (!this.$v.$error) {
      const v = {
        name: this.userMainInfo.name,
        birthday: this.userMainInfo.birthday,
        gender: this.userMainInfo.gender.length > 0 ? this.userMainInfo.gender[0].name : '',
        phone: this.userMainInfo.phone,
        address: this.userMainInfo.address,
        email: this.userMainInfo.email,
        country: this.userMainInfo.country.length > 0 ? this.userMainInfo.country[0].id : 0,
        website: this.userMainInfo.website,
        lang: this.userMainInfo.lang.length > 0 ? this.userMainInfo.lang[0].value : '',
        timezone: this.userMainInfo.timezone,
        background: this.userMainInfo.background,
        quote: this.userMainInfo.quote,
        ln: this.userMainInfo.ln,
        fb: this.userMainInfo.fb,
        inst: this.userMainInfo.inst,
        photo: this.imagePath
      }
      this.$axios({
        method: 'post',
        headers: {
          Authorization: 'Bearer ' + this.userSecurity.token,
          'X-Api-Key': this.userSecurity.key
        },
        url: this.$root.$data.baseURL + 'apicall/coach/profilecreate',
        params: {
          data: JSON.stringify(v)
        }
      })
        .then((r) => {
          console.log('DONE')
        })
        .catch(function (e) {
          console.log(e.response)
        })
    }
  }
}
</script>

<style lang="scss">
.profile-ed-mn{
  padding: 24px;
  border-radius: 16px;
  background-color: #fff;
  &-image{
    width: 56px;
    height: 56px;
    border-radius: 50%;
    overflow: hidden;
    img{
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 50%;
    }
  }
}
.vc-container{
  background-color: #f7f7fa;
  border-radius: 6px;
  border: none;
  .vc-title{
    font-size: 16px;
    font-weight: 500;
    color: #000000;
    text-transform: capitalize;
    transition: all .34s ease-out;
  }
  .vc-day-content{
    width: 32px;
    height: 32px;
    line-height: 32px;
    border-radius: 6px;
    color: #000000;
    font-size: 14px;
    transition: all .34s ease-out;
    font-weight: 400!important;
    &.is-disabled{
      pointer-events: none;
    }
  }
  .vc-highlight{
    width: 32px;
    height: 32px;
    transition: all .34s ease-out;
    background-color: #5E4BF1!important;
    border-radius: 6px!important;
  }
  .vc-day{
    margin-top: 1px;
    margin-bottom: 1px;
    &.is-not-in-month *{
      opacity: 1;
      color: #AAAAAA;
    }
  }
  .vc-weekday{
    color: #AAAAAA;
    font-size: 15px;
    font-weight: 500;
    text-transform: capitalize;
  }
  .vc-arrows-container{
    padding-top: 14px;
  }
  .vc-arrow{
    color: #161616;
    width: 32px;
    height: 32px;
    border-radius: 6px;
    padding-bottom: 2px;
    transition: all .34s ease-out;
  }
  .vc-header{
    padding-top: 14px;
    margin-bottom: 8px;
  }
  .vc-nav-popover-container{
    background-color: #f7f7fa;
    border: 1px solid rgba(0, 0, 0, 0.06);
    color: #161616;
  }
  .vc-nav-item{
    font-weight: 400;
    text-transform: capitalize;

    &.is-current{
      color: #161616;
    }
    &.is-active{
      box-shadow: none;
      background-color: #5E4BF1!important;
      color: #fff!important;
    }
  }
  .vc-nav-arrow{
    transition: all .34s ease-out;
  }
  .vc-nav-header{
    margin-bottom: 4px;
  }
  .vc-nav-title{
    color: #161616;
    font-weight: 500;
    font-size: 16px;
    transition: all .34s ease-out;
  }
  .vc-nav-title:hover{
    background-color: rgba(0, 0, 0, 0.08);
  }
  .vc-nav-arrow:hover{
    background-color: rgba(0, 0, 0, 0.08);
  }
  .vc-nav-item:hover {
    background-color: rgba(0, 0, 0, 0.08);
    color: #161616;
    box-shadow: none;
  }
  .vc-arrow:hover {
    background-color: rgba(0, 0, 0, 0.08);
  }
  .vc-day-content:hover {
    background-color: rgba(0, 0, 0, 0.08);
  }
}

</style>
