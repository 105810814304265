<template>
  <div class="lb-tp-40">
    <div v-if="!loadingLocal">
      <div class="h3 lb-mb-24">{{getTitle}}</div>
      <div class="row">
        <div class="col-lg-7">
          <div class="form-group sm">
            <div class="lb-mb-15">
              <div class="input-wrapper-material" :class="{'typed': userAchievements.since}">
                <div class="input-inner">
                  <label>Coach since</label>
                  <vc-date-picker
                    v-model="userAchievements.since"
                    is24hr
                    :locale="{ id: 'en', masks: { weekdays: 'WW' } }"
                  >
                    <template v-slot="{ inputValue, inputEvents }">
                      <input
                        class="input input-material"
                        @focus="focusInput"
                        @blur="blurInput($event); $v.userAchievements.since.$touch()"
                        @input="typedInput"
                        :class="{'error': $v.userAchievements.since.$error}"
                        :value="inputValue"
                        v-on="inputEvents"
                      />
                    </template>
                  </vc-date-picker>
                </div>
                <div v-if="$v.userAchievements.since.$error" class="input-error">Error</div>
              </div>
              <div class="input-wrapper-material">
                <div class="input-inner">
                  <label>Name of coaching school</label>
                  <input
                    class="input input-material"
                    name="name" type="text"
                    @focus="focusInput"
                    @blur="blurInput($event); $v.userAchievements.school.$touch()"
                    @input="typedInput"
                    v-model="userAchievements.school"
                    :class="{'error': $v.userAchievements.school.$error}"
                  >
                </div>
                <div v-if="$v.userAchievements.school.$error" class="input-error">Error</div>
              </div>
              <div class="select-wrapper-material" ref="selectB" :class="{'selected': userAchievements.coachingCer.length}" >
                <label>Coaching certification</label>
                <multi-select
                  v-model="userAchievements.coachingCer"
                  :btnLabel="v => v.length ?  labelSelect(v) : 'Select'"
                  :selectOptions="optionsCert"
                  :options="{labelName: 'title', multi: true}"
                  @open="openSelect($refs.selectB)"
                  @close="closeSelect($refs.selectB)"
                >
                  <template v-slot:option="{option}">
                    <div class="checkbox-entry type2">
                      <input type="checkbox" class="subscribe-input" :checked="option.selected">
                      <span>{{option.title}}</span>
                    </div>
                  </template>
                </multi-select>
              </div>
              <div class="select-wrapper-material lb-mb-15" ref="selectC" :class="{'selected': userAchievements.federation.length}" >
                <label>Coaching federation member</label>
                <multi-select
                  v-model="userAchievements.federation"
                  :btnLabel="v => v.length ? v[0].title : 'Select'"
                  :selectOptions="optionsFeder"
                  :options="{labelName: 'title', cssSelected: option => (option.selected ? { 'background-color': '#f5f7fa' } : '')}"
                  @open="openSelect($refs.selectC)"
                  @close="closeSelect($refs.selectC)"
                />
              </div>
            </div>
            <div class="lb-mb-15">
              <div class="lb-mb-8 text-md">Coaching school diploma as business coach</div>
              <div class="upload-wrapper">
                <button
                  @click="triggerUpload"
                  class="btn btn-outlined btn-loader"
                  :class="{'loader': loadingFiles}"
                >Upload file</button>
                <br>
                <div v-if="files.length > 0">
                  <div
                    class="upload-die w-100"
                    v-for="(item, i) in files"
                    :key="i + 'p'"
                  >
                  <span class="icon">
                    <img src="@/assets/img/icons/icon-ok.svg" alt="icon">
                  </span>
                    <span class="file">{{item.name}}</span>
                    <span class="size">{{Math.ceil(item.size / (1024 * 1024))}} MB</span>
                    <button
                      class="btn clean"
                      @click="removeFile(i, item.id)"
                    >
                      <img src="@/assets/img/icons/icon-clear.svg" alt="icon">
                    </button>
                  </div>
                </div>
                <input
                  @change="changeFile($event)"
                  ref="fileInput"
                  type="file"
                  style="display: none"
                  accept="application/pdf,application/vnd.ms-excel"
                >
              </div>
            </div>
            <div class="lb-mb-15">
              <div class="lb-mb-8 text-md">Do you have a role in a coaching federation?</div>
              <div class="badge-wrapper">
                <label class="badge checkbox-item">
                  <input name="radio-01" value="Yes" type="radio" v-model="userAchievements.federation_role"><span>Yes</span>
                </label>
                <label class="badge checkbox-item">
                  <input name="radio-01" value="No" type="radio" v-model="userAchievements.federation_role"><span>No</span>
                </label>
              </div>
            </div>
            <div class="lb-mb-15">
              <div class="lb-mb-8 text-md">Years of management experience</div>
              <div class="badge-wrapper">
                <label class="badge checkbox-item">
                  <input name="radio-02" value="0" type="radio" v-model="userAchievements.managementExp"><span>0</span>
                </label>
                <label class="badge checkbox-item">
                  <input name="radio-02" value="1-2" type="radio" v-model="userAchievements.managementExp"><span>1-2</span>
                </label>
                <label class="badge checkbox-item">
                  <input name="radio-02" value="3-4" type="radio" v-model="userAchievements.managementExp"><span>3-4</span>
                </label>
                <label class="badge checkbox-item">
                  <input name="radio-02" value="4-5" type="radio" v-model="userAchievements.managementExp"><span>4-5</span>
                </label>
                <label class="badge checkbox-item">
                  <input name="radio-02" value="6-7" type="radio" v-model="userAchievements.managementExp"><span>6-7</span>
                </label>
                <label class="badge checkbox-item">
                  <input name="radio-02" value=">7" type="radio" v-model="userAchievements.managementExp"><span>>7</span>
                </label>
              </div>
            </div>
            <div class="lb-mb-15">
              <div class="lb-mb-8 text-md">Years of leadership experience</div>
              <div class="badge-wrapper">
                <label class="badge checkbox-item">
                  <input name="radio-03" value="0" type="radio" v-model="userAchievements.leadershipExp"><span>0</span>
                </label>
                <label class="badge checkbox-item">
                  <input name="radio-03" value="1-2" type="radio" v-model="userAchievements.leadershipExp"><span>1-2</span>
                </label>
                <label class="badge checkbox-item">
                  <input name="radio-03" value="3-4" type="radio" v-model="userAchievements.leadershipExp"><span>3-4</span>
                </label>
                <label class="badge checkbox-item">
                  <input name="radio-03" value="4-5" type="radio" v-model="userAchievements.leadershipExp"><span>4-5</span>
                </label>
                <label class="badge checkbox-item">
                  <input name="radio-03" value="6-7" type="radio" v-model="userAchievements.leadershipExp"><span>6-7</span>
                </label>
                <label class="badge checkbox-item">
                  <input name="radio-03" value="7" type="radio" v-model="userAchievements.leadershipExp"><span>>7</span>
                </label>
              </div>
            </div>
            <div class="lb-mb-15">
              <div class="lb-mb-8 text-md">How many years have you led a group of people or an organisation?</div>
              <div class="badge-wrapper">
                <label class="badge checkbox-item">
                  <input name="radio-04" value="0" type="radio" v-model="userAchievements.ledGroupExp"><span>0</span>
                </label>
                <label class="badge checkbox-item">
                  <input name="radio-04" value="1-2" type="radio" v-model="userAchievements.ledGroupExp"><span>1-2</span>
                </label>
                <label class="badge checkbox-item">
                  <input name="radio-04" value="3-4" type="radio" v-model="userAchievements.ledGroupExp"><span>3-4</span>
                </label>
                <label class="badge checkbox-item">
                  <input name="radio-04" value="4-5" type="radio" v-model="userAchievements.ledGroupExp"><span>4-5</span>
                </label>
                <label class="badge checkbox-item">
                  <input name="radio-04" value="6-7" type="radio" v-model="userAchievements.ledGroupExp"><span>6-7</span>
                </label>
                <label class="badge checkbox-item">
                  <input name="radio-04" value="7" type="radio" v-model="userAchievements.ledGroupExp"><span>>7</span>
                </label>
              </div>
            </div>
            <div class="select-wrapper-material top" ref="selectA" :class="{'selected': userAchievements.how_learn.length}" >
              <label>How did you learn about Competenz Lab?</label>
              <multi-select
                v-model="userAchievements.how_learn"
                :btnLabel="v => v.length ? v[0].title : 'Select'"
                :selectOptions="optionsHowL"
                :options="{labelName: 'title', cssSelected: option => (option.selected ? { 'background-color': '#f5f7fa' } : '')}"
                @open="openSelect($refs.selectA)"
                @close="closeSelect($refs.selectA)"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="loader-wrapper">
      <div class="loader-circle">
        <img src="@/assets/img/loader-icon.png" alt="loader">
      </div>
    </div>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import MultiSelect from 'vue-multi-select'
import 'vue-multi-select/dist/lib/vue-multi-select.css'

export default {
  props: ['clickedNext', 'currentStep', 'done'],
  data () {
    return {
      fileUpload: null,
      files: [],
      loadingFiles: false,
      userAchievements: {
        since: '',
        school: '',
        coachingCer: [],
        federation: [],
        federation_role: '',
        managementExp: '',
        leadershipExp: '',
        ledGroupExp: '',
        how_learn: []
      },
      optionsCert: [],
      loadingCert: true,
      optionsFeder: [],
      loadingFeder: true,
      optionsHowL: [],
      loadingHowL: true
    }
  },
  computed: {
    getStep () {
      return this.$store.getters.steps[this.currentStep.index]
    },
    getOptions () {
      return this.getStep.options
    },
    getTitle () {
      return this.getStep.titleStep
    },
    loadingLocal () {
      if (!this.loadingCert && !this.loadingFeder && !this.loadingHowL) {
        return false
      } else {
        return true
      }
    },
    userSecurity () {
      return this.$store.getters.user
    }
  },
  methods: {
    focusInput: function (e) {
      e.target.closest('.input-wrapper-material').classList.add('focus')
    },
    blurInput: function (e) {
      e.target.closest('.input-wrapper-material').classList.remove('focus')
    },
    typedInput: function (e) {
      if (e.target.value) {
        e.target.closest('.input-wrapper-material').classList.add('typed')
      } else {
        e.target.closest('.input-wrapper-material').classList.remove('typed')
      }
    },
    openSelect: function (e) {
      e.classList.add('open-select')
    },
    closeSelect: function (e) {
      e.classList.remove('open-select')
    },
    triggerUpload: function () {
      this.$refs.fileInput.click()
    },
    changeFile: function (event) {
      const file = event.target.files[0]
      const formData = new FormData()
      formData.set('file', file)
      // POST FILE
      if (!this.files.some(e => e.name === file.name)) {
        this.loadingFiles = true
        this.$axios.post(this.$root.$data.baseURL + 'apicall/coach/savediploma', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: 'Bearer ' + this.userSecurity.token,
            'X-Api-Key': this.userSecurity.key
          }
        })
          .then((r) => {
            this.files.push({
              id: r.data.file_id.id,
              name: file.name,
              size: file.size
            })
            this.loadingFiles = false
          })
          .catch(function (e) {
            this.loadingFiles = false
            console.log(e.response)
          })
      }
      // POST FILE (end)
      event.target.value = ''
    },
    removeFile: function (i, id) {
      this.$axios({
        method: 'post',
        headers: {
          Authorization: 'Bearer ' + this.userSecurity.token,
          'X-Api-Key': this.userSecurity.key
        },
        url: this.$root.$data.baseURL + 'apicall/coach/deletediploma',
        params: {
          file_id: id
        }
      })
        .then((r) => {
          this.files.splice(i, 1)
        })
        .catch(function (e) {
          console.log(e.response)
        })
    },
    labelSelect: function (e) {
      if (e.length > 1) {
        return e[0].title + ', ... (' + e.length + ')'
      } else {
        return e[0].title
      }
    }
  },
  watch: {
    userAchievements: {
      handler (val) {
        if (!this.$v.$invalid) {
          this.$emit('can-continue', { value: true })
        } else {
          this.$emit('can-continue', { value: false })
        }
      },
      deep: true
    }
  },
  mounted () {
    if (!this.$v.$error) {
      this.$emit('can-continue', { value: true })
    } else {
      this.$emit('can-continue', { value: false })
    }
    this.$axios({
      method: 'get',
      url: this.$root.$data.baseURL + 'apicall/coach/getcertifications'
    })
      .then((r) => {
        this.optionsCert = r.data
        this.loadingCert = false
      })
      .catch(function (e) {
        this.loadingCert = false
      })
    this.$axios({
      method: 'get',
      url: this.$root.$data.baseURL + 'apicall/coach/getfederations'
    })
      .then((r) => {
        this.optionsFeder = r.data
        this.loadingFeder = false
      })
      .catch(function (e) {
        this.loadingFeder = false
      })
    this.$axios({
      method: 'get',
      url: this.$root.$data.baseURL + 'apicall/coach/gethowlearnabout'
    })
      .then((r) => {
        this.optionsHowL = r.data
        this.loadingHowL = false
      })
      .catch(function (e) {
        this.loadingHowL = false
      })
  },
  components: {
    MultiSelect
  },
  validations: {
    userAchievements: {
      since: {
        required
      },
      school: {
        required
      }
    }
  },
  beforeDestroy () {
    if (!this.$v.$error) {
      const v = {
        since: this.userAchievements.since,
        school: this.userAchievements.school,
        federation_id: this.userAchievements.federation.length > 0 ? this.userAchievements.federation[0].id : null,
        federation_role: this.userAchievements.federation_role,
        management_experience: this.userAchievements.managementExp,
        leadership_experience: this.userAchievements.leadershipExp,
        led_a_group: this.userAchievements.ledGroupExp,
        certs: this.userAchievements.coachingCer.map(e => e.id),
        how_learn_id: this.userAchievements.how_learn.length > 0 ? this.userAchievements.how_learn[0].id : null
      }
      this.$axios({
        method: 'post',
        headers: {
          Authorization: 'Bearer ' + this.userSecurity.token,
          'X-Api-Key': this.userSecurity.key
        },
        url: this.$root.$data.baseURL + 'apicall/coach/achievementscreate',
        params: {
          data: JSON.stringify(v)
        }
      })
        .then((r) => {
          this.$store.commit('uploadCoachUsr', r.data.string)
          console.log('DONE')
        })
        .catch(function (e) {
          console.log(e.response)
        })
    }
  }
}
</script>
<style lang="scss">
.checkbox-entry{
  &.type2{
    margin-bottom: 0!important;
    font-weight: 400;
  }
}
</style>
