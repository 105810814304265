import store from '../store'

export default function (to, from, next) {
  // next()
  if (store.getters.user) {
    next()
  } else {
    if (to.query.usr) {
      store.commit('usrUpload', to.query.usr)
      store.dispatch('autoLoginUser', to.query.usr).then((r) => {
        if (store.getters.user) {
          next()
        } else {
          window.location.replace(store.getters.loginURL)
        }
      }, (error) => {
        window.location.replace(store.getters.loginURL)
        console.log(error)
      })
    } else {
      window.location.replace(store.getters.loginURL)
    }
  }
}
