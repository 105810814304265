import LabStepCheckbox from '@/components/LabStepCheckbox'
import LabStepTwo from '@/components/LabStepTwo'
import LabStepCheckboxSearch from '@/components/LabStepCheckboxSearch'
import LabStepFifth from '@/components/LabStepFifth'
import LabStepRadio from '@/components/LabStepRadio'
import axios from 'axios'

function getComponents (c) {
  switch (c) {
    case 'LabStepCheckbox': return LabStepCheckbox
    case 'LabStepTwo': return LabStepTwo
    case 'LabStepCheckboxSearch': return LabStepCheckboxSearch
    case 'LabStepFifth': return LabStepFifth
    case 'LabStepRadio': return LabStepRadio
  }
}

export default {
  state: {
    pl: ''
  },
  actions: {
    async fetchSteps ({ commit, getters }) {
      commit('clearError')
      commit('setLoading', true)
      try {
        const val = await axios.get(getters.baseURL + 'apicall/onboarding/profiletype', {
          headers: {
            Authorization: 'Bearer ' + getters.user.token,
            'X-Api-Key': getters.user.key
          }
        })
        const resultSteps = val.data.map(function (e) {
          return {
            completed: e.completed,
            component: getComponents(e.component),
            name: e.name,
            options: e.options,
            titleStep: e.titleStep,
            title: e.title,
            isTitleBlock: true
          }
        })
        // console.log(val.data)
        commit('loadSteps', resultSteps)
        commit('setLoading', false)
      } catch (error) {
        console.log(error.response)
        commit('setError', error)
        commit('setLoading', false)
      }
    }
  }
}
