<template>
  <div>
    <div class="container-cs">
      <h1 class="mb-50">Elements</h1>

      <div>* ------------ *</div>
      Typography
      <div class="mb-20">* ------------ *</div>
      <h1>Typographi H1</h1>
      <h2>Typographi H2</h2>
      <h3>Typographi H3</h3>
      <h4>Typographi H4</h4>

      <div class="text">
        Lorem ipsum dolor sit amet, consectetur. (.text)
      </div>
      <div class="text-md">
        Lorem ipsum dolor sit amet, consectetur. (.text-md)
      </div>
      <div class="text-sm">
        Lorem ipsum dolor sit amet, consectetur. (.text-sm)
      </div>

      <div class="mb-50"></div>

      <div>* ------------ *</div>
      Buttons
      <div class="mb-20">* ------------ *</div>
      <div class="mb-10">Primary</div>
      <button class="btn btn-primary">Start project</button>
      <hr class="hr-s">
      <button class="btn btn-primary is-active">Start project</button>
      <hr class="hr-s">
      <button class="btn btn-primary disable">Start project</button>
      <div class="mb-10 mt-10">Secondary</div>
      <button class="btn btn-secondary">Start project</button>
      <hr class="hr-s">
      <button class="btn btn-secondary is-active">Start project</button>
      <hr class="hr-s">
      <button class="btn btn-secondary disable">Start project</button>
      <div class="mb-10 mt-10">Outlined</div>
      <button class="btn btn-outlined">Start project</button>
      <hr class="hr-s">
      <button class="btn btn-outlined is-active">Start project</button>
      <hr class="hr-s">
      <button class="btn btn-outlined disable">Start project</button>

      <div class="mb-10 mt-10">Ghost</div>
      <button class="btn btn-ghost">Start project</button>
      <hr class="hr-s">
      <button class="btn btn-ghost is-active">Start project</button>
      <hr class="hr-s">
      <button class="btn btn-ghost disable">Start project</button>

      <div class="mb-50"></div>

      <div>* ------------ *</div>
      Selection control
      <div class="mb-20">* ------------ *</div>

      <div class="mb-10">Checkbox</div>
      <label class="checkbox-entry">
        <input class="subscribe-input" type="checkbox">
        <span></span>
      </label>

      <label class="checkbox-entry">
        <input class="subscribe-input" type="checkbox" checked="">
        <span></span>
      </label>

      <label class="checkbox-entry disable">
        <input class="subscribe-input" type="checkbox">
        <span></span>
      </label>

      <label class="checkbox-entry disable-checked">
        <input class="subscribe-input" type="checkbox" checked="">
        <span></span>
      </label>

      <hr class="hr-s">

      <label class="checkbox-entry">
        <input class="subscribe-input" type="radio">
        <span></span>
      </label>

      <label class="checkbox-entry">
        <input class="subscribe-input" type="radio" checked="">
        <span></span>
      </label>

      <label class="checkbox-entry disable">
        <input class="subscribe-input" type="radio">
        <span></span>
      </label>

      <label class="checkbox-entry disable-checked">
        <input class="subscribe-input" type="radio" checked="">
        <span></span>
      </label>

      <div class="mb-50"></div>
      <div>* ------------ *</div>
      Text fields
      <div class="mb-20">* ------------ *</div>

      <div class="input-wrapper-material">
        <label>Placeholder</label>
        <input class="input input-material" name="name" type="text" @focus="focusInput" @blur="blurInput" @input="typedInput">
      </div>

      <hr class="hr-s">

      <div class="input-wrapper-material typed">
        <label>Placeholder</label>
        <input class="input input-material" name="name" value="text" type="text" @focus="focusInput" @blur="blurInput" @input="typedInput">
      </div>

      <hr class="hr-s">

      <div class="input-wrapper-material dispbled">
        <label>Placeholder</label>
        <input class="input input-material" name="name" type="text" @focus="focusInput" @blur="blurInput" @input="typedInput">
      </div>

      <hr class="hr-s">

      <div class="input-wrapper-material dispbled typed">
        <label>Placeholder</label>
        <input class="input input-material" name="name" value="text" type="text" @focus="focusInput" @blur="blurInput" @input="typedInput">
      </div>

      <hr class="hr-s">

      <div class="input-wrapper-material typed error">
        <label>Placeholder</label>
        <input class="input input-material error" name="name" value="text" type="text" @focus="focusInput" @blur="blurInput" @input="typedInput">
        <div class="input-error">Error</div>
      </div>

      <div class="mb-50"></div>

      <div>* ------------ *</div>
      Select
      <div class="mb-20">* ------------ *</div>

      <div class="select-wrapper-material" :class="{'open-select': isOpenSelect, 'selected': valueSelect.length}" >
        <label>County</label>
        <multi-select
          v-model="valueSelect"
          :btnLabel="btnLabel"
          :selectOptions="selectdata"
          @open="isOpenSelect = true"
          @close="isOpenSelect = false"
        />
      </div>

      <div class="mb-50"></div>
      <div>* ------------ *</div>
      Loader
      <div class="mb-20">* ------------ *</div>
      <div class="loader-wrapper">
        <div class="loader-circle">
          <img src="@/assets/img/loader-icon.png" alt="loader">
        </div>
      </div>
      <div class="loader-wrapper">
        <div class="loader-circle md">
          <img src="@/assets/img/loader-icon.png" alt="loader">
        </div>
      </div>
      <div class="loader-wrapper">
        <div class="loader-circle sm">
          <img src="@/assets/img/loader-icon.png" alt="loader">
        </div>
      </div>
      <div class="loader-wrapper">
        <div class="loader-circle">
          <img src="@/assets/img/loader-image-2.svg" alt="loader">
        </div>
      </div>

      <div class="mb-50"></div>
      <div>* ------------ *</div>
      Search
      <div class="mb-20">* ------------ *</div>

      <div class="input-wrapper-icon clean">
        <span class="icon"><img src="@/assets/img/icons/icon-search.svg" alt="icon-search"></span>
        <button
          class="icon-clean"
          v-if="searchSkills.length"
          @click="searchSkills = ''"
        >
          <img src="@/assets/img/icons/icon-clear.svg" alt="icon-search">
        </button>
        <input class="input sm" placeholder="Search for skills" type="text" v-model="searchSkills">
      </div>
      <div class="mb-20"></div>
      <div class="input-wrapper-icon">
        <span class="icon"><img src="@/assets/img/icons/icon-search.svg" alt="icon-search"></span>
        <input class="input error sm" placeholder="Search for skills" type="text">
      </div>
      <div class="input-error">Error</div>
      <div class="mb-20"></div>
      <div class="input-wrapper-icon clean">
        <span class="icon"><img src="@/assets/img/icons/icon-search-light.svg" alt="icon-search"></span>
        <button
          class="icon-clean"
          v-if="searchSkills.length"
          @click="searchSkills = ''"
        >
          <img src="@/assets/img/icons/icon-clear.svg" alt="icon-search">
        </button>
        <input class="input light sm" placeholder="Search for skills" type="text" v-model="searchSkills">
      </div>
      <div class="mb-20"></div>
      <div class="input-wrapper-icon">
        <span class="icon"><img src="@/assets/img/icons/icon-search-light.svg" alt="icon-search"></span>
        <input class="input light error sm" placeholder="Search for skills" type="text">
      </div>
      <div class="input-error">Error</div>

      <div class="mb-50"></div>
      <div>* ------------ *</div>
      Uploader
      <div class="mb-20">* ------------ *</div>
      <div class="upload-wrapper">
        <button
          @click="triggerUpload"
          class="btn btn-outlined"
        >Upload CV</button>
        <br>
        <div class="upload-die" v-if="fileUpload">
          <span class="icon">
            <img src="@/assets/img/icons/icon-ok.svg" alt="icon">
          </span>
          <span class="file">{{fileUploadInfo.name}}</span>
          <span class="size">{{fileUploadInfo.size}} MB</span>
          <button
            class="btn clean"
            @click="fileUpload = null"
          >
            <img src="@/assets/img/icons/icon-clear.svg" alt="icon">
          </button>
        </div>
        <input
          @change="changeFile($event)"
          ref="fileInput"
          type="file"
          style="display: none"
          accept="application/pdf,application/vnd.ms-excel"
        >
      </div>

      <div style="margin-bottom: 700px;"></div>

    </div>
  </div>
</template>

<script>
import MultiSelect from 'vue-multi-select'
import 'vue-multi-select/dist/lib/vue-multi-select.css'

export default {
  data () {
    return {
      btnLabel: values => values.length ? values[0].name : 'Move to',
      valueSelect: [],
      isOpenSelect: false,
      searchSkills: '',
      selectdata: [
        { name: 'caunty1' },
        { name: 'caunty2' },
        { name: 'caunty3' },
        { name: 'caunty4' },
        { name: 'caunty5' },
        { name: 'caunty1' },
        { name: 'caunty2' },
        { name: 'caunty3' },
        { name: 'caunty4' },
        { name: 'caunty5' },
        { name: 'caunty1' },
        { name: 'caunty2' },
        { name: 'caunty3' },
        { name: 'caunty4' },
        { name: 'caunty5' },
        { name: 'caunty6' }
      ],
      fileUpload: null
    }
  },
  computed: {
    fileUploadInfo () {
      return {
        name: this.fileUpload.name,
        size: Math.ceil(this.fileUpload.size / (1024 * 1024))
      }
    }
  },
  methods: {
    focusInput: function (e) {
      e.target.closest('.input-wrapper-material').classList.add('focus')
    },
    blurInput: function (e) {
      e.target.closest('.input-wrapper-material').classList.remove('focus')
    },
    typedInput: function (e) {
      if (e.target.value) {
        e.target.closest('.input-wrapper-material').classList.add('typed')
      } else {
        e.target.closest('.input-wrapper-material').classList.remove('typed')
      }
    },
    checkFn: function (e) {
      console.log(this.isSelectEmpty)
    },
    triggerUpload: function () {
      this.$refs.fileInput.click()
    },
    changeFile: function (event) {
      const file = event.target.files[0]
      const reader = new FileReader()

      reader.readAsDataURL(file)
      this.fileUpload = file
      console.log(this.fileUpload)
    }
  },
  components: {
    MultiSelect
  }
}

</script>

<style lang="scss">

.mb-50{
  margin-bottom: 50px;
}
.mb-20{
  margin-bottom: 20px;
}
.mb-10{
  margin-bottom: 20px;
}

.mt-10{
  margin-top: 10px;
}

.hr-s{
  border-bottom: 1px solid #D6DDEA;
  display: block;
  width: 100%;
  margin: 10px 0;
}

</style>
