import Vue from 'vue'
import App from './App.vue'
import router from './router'
import VueAgile from 'vue-agile'
import Vuelidate from 'vuelidate'
import VCalendar from 'v-calendar'
import './assets/scss/style.scss'
import store from './store'
import axios from 'axios'
import superagent from 'superagent'

Vue.use(VueAgile)
Vue.use(Vuelidate)
Vue.use(VCalendar, {
  componentPrefix: 'vc'
})

Vue.prototype.$axios = axios
Vue.prototype.$superagent = superagent
Vue.config.productionTip = false

new Vue({
  data () {
    return {
      baseURL: 'https://api.competenzlab.io/'
    }
  },
  router,
  store,
  render: h => h(App),
  created () {
    // // console.log('created app')
    // if (this.$route.query) {
    //   // console.log(this.$route.query.usr)
    //   // this.$store.dispatch('autoLoginUser', this.$route.query.usr)
    //   // this.$router.push(this.$route.path)
    // } else {
    //   console.error('query params not')
    // }
  }
}).$mount('#app')
