<template>
  <div class="banner-rg">
    <div class="banner-rg-slider">
      <div v-if="bannerText.length">
        <slide-container
          :options="optionSlide"
        >
          <div v-for="(slide, n) in bannerText" :key="n">
            <div class="h3 lb-mb-8 color-dark banner-rg-title">{{slide.title}}</div>
            <div class="text color-grey banner-rg-text">
              <p>{{slide.text}}</p>
            </div>
          </div>
        </slide-container>
      </div>
      <div v-else class="loader-wrapper">
        <div class="loader-circle">
          <img src="@/assets/img/loader-icon.png" alt="loader">
        </div>
      </div>
    </div>
    <div class="banner-rg-image">
      <img src="@/assets/img/image-03993.png" alt="image">
    </div>
  </div>
</template>

<script>
import { VueAgile } from 'vue-agile'

export default {
  data () {
    return {
      slideText: [
        { title: 'Individual plan', text: 'Our specialists develop an individual development plan for a period set by you. Study all the necessary material, improve your hard skills and soft skills.' },
        { title: 'Individual plan 2', text: 'Our specialists develop an individual development plan for a period set by you. Study all the necessary material, improve your hard skills and soft skills.' },
        { title: 'Individual plan 3', text: 'Our specialists develop an individual development plan for a period set by you. Study all the necessary material, improve your hard skills and soft skills.' },
        { title: 'Individual plan 4', text: 'Our specialists develop an individual development plan for a period set by you. Study all the necessary material, improve your hard skills and soft skills.' }
      ],
      optionSlide: {
        navButtons: false,
        dots: true
      }
    }
  },
  computed: {
    bannerText () {
      return this.$store.getters.banners
    }
  },
  created () {
    this.$store.dispatch('fetchBanner')
  },
  components: {
    slideContainer: VueAgile
  }
}
</script>

<style lang="scss">
@import "../assets/scss/_variables.scss";
@import "../assets/scss/_lab-banner.scss";
</style>
