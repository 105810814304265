<template>
  <div class="lb-tp-60">
    <div v-if="getStep.isTitleBlock">
      <div class="title-wrapper">
          <span class="icon">
            <img src="@/assets/img/icons/rocket-icon-0234.svg" alt="icon">
          </span>
        <div class="h2 fw-700 lb-mb-5">Profile type</div>
        <div class="text-md color-grey2">Objectives and goals: what do you want to achieve?</div>
      </div>
    </div>
    <div class="wrapper-layer" :class="'step-' + this.currentStep.index">
      <div class="h1 fw-500 lb-mb-20">{{getTitle}}</div>
      <tamplate v-if="getOptions.length < 4">
        <div class="checkbox-wrapper">
          <label class="checkbox-entry" v-for="(option, n) in getOptions" :key="n">
            <input type="checkbox" class="subscribe-input" :value="option.key" v-model="optionVal">
            <span>{{option.value}}</span>
          </label>
        </div>
      </tamplate>
      <tamplate v-else>
        <div class="row checkbox-wrapper">
          <div class="col-lg-5" v-for="(option, n) in getOptions" :key="n">
            <label class="checkbox-entry">
              <input type="checkbox" class="subscribe-input" :value="option.key" v-model="optionVal">
              <span>{{option.value}}</span>
            </label>
          </div>
        </div>
      </tamplate>
    </div>
  </div>
</template>

<script>

export default {
  props: ['clickedNext', 'currentStep'],
  data () {
    return {
      optionVal: []
    }
  },
  computed: {
    getStep () {
      return this.$store.getters.steps[this.currentStep.index]
    },
    getOptions () {
      return this.getStep.options
    },
    getTitle () {
      return this.getStep.titleStep
    }
  },
  watch: {
    optionVal: function (val) {
      const a = []
      this.getOptions.forEach((c) => {
        if (val.indexOf(c.key) !== -1) {
          a.push(c.key)
        }
      })
      // post answe
      const v = {
        indexStep: this.currentStep.index,
        answer: a
      }
      this.$store.commit('updateStepsAnswer', v)
      // post answe the end

      if (a.length > 0) {
        this.$emit('can-continue', { value: true })
        this.$store.commit('canContinue', true)
      } else {
        this.$emit('can-continue', { value: false })
        this.$store.commit('canContinue', false)
      }
    }
  },
  mounted () {
    this.optionVal = []
    this.$store.commit('is_send', true)
    if (this.optionVal.length > 0) {
      this.$emit('can-continue', { value: true })
      // post answe
      const a = []
      this.getOptions.forEach((c) => {
        if (this.optionVal.indexOf(c.key) !== -1) {
          a.push(c.key)
        }
      })
      const v = {
        indexStep: this.currentStep.index,
        answer: a
      }
      this.$store.commit('updateStepsAnswer', v)
      // post answe the end
    } else {
      this.$emit('can-continue', { value: false })
    }
  }
}
</script>
